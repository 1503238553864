<script>
  import PurplePolkaLogo from "./PurplePolkaLogo.svelte";

  let isMobile, w;

  $: if (w >= 1024) {
    isMobile = false;
  } else {
    isMobile = true;
  }
</script>

<style lang="scss">.get-started-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #ffffff;
  padding-top: 15vw;
}

a > svg {
  fill: #5f1ec4;
}

h1.is-large {
  max-width: 100vw;
  font-size: 5rem;
  letter-spacing: -0.1rem;
}

h1.is-large.is-mobile {
  font-size: 8vw;
  letter-spacing: -0.2vw;
}

h1 {
  color: #141414;
}

a {
  color: #5f1ec4;
}

a.drawn-outline {
  position: relative;
}

a.drawn-outline > svg {
  position: absolute;
  width: 32rem;
  left: -1.1rem;
  top: -3rem;
}

a.drawn-outline.is-mobile > svg {
  width: 50vw;
  left: -1.5vw;
  top: -4vw;
}

a.drawn-outline > svg path {
  fill: #5f1ec4;
}

svg {
  position: absolute;
  top: 14px;
  left: 9px;
}
svg > path {
  fill: #5f1ec4;
}

.button {
  border: none;
}
.button:focus {
  color: #ffffff;
}

.calendly__outer {
  margin-top: 3rem;
}</style>

<div class="get-started-container">
  <PurplePolkaLogo />
  <h1 id="#getStarted" class="title is-large" class:is-mobile="{isMobile}">
    Ready?
    <a
      class="drawn-outline"
      class:is-mobile="{isMobile}"
      href="https://calendly.com/dennis-dna-pathways/dna-pathways"
      target="_blank">
      <svg viewBox="0 0 300 93" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M112.09 14.5877C111.884 14.8467 112.02 15.1767 112.607 14.8827L113
          14.0697L112.09 14.5877Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M119.65 12.8121C121.057 12.5652 122.677 12.2238 121.704
          12.0339C120.327 12.4003 119.964 12.3187 118.491 12.7463C117.275
          12.8704 116.345 12.8192 116.532 12.7026C116.113 12.8054 114.624
          12.7854 114 13.0065C115.629 13.0985 117.638 12.9561 119.708
          12.6231C120.169 12.6493 119.744 12.7538 119.65 12.8121Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M207 16.6534L206 16.4144C206.27 16.5014 206.614 16.5804 207
          16.6534Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M258.98 28.6788C258.339 28.3758 258.048 28.3248 258 28.3618C258.61
          28.5948 259.106 28.8048 258.98 28.6788Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M267 31.7944C266.635 31.6054 266.329 31.4454 266 31.2734C266.363
          31.5124 266.739 31.7274 267 31.7944Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M206 16.2234L206.993 16.8444C207.073 16.6604 206.486 16.4734 206
          16.2234Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M131 12.3854C130.613 12.4654 130.293 12.5664 130 12.6824C130.318
          12.6194 130.626 12.5594 130.944 12.4964C130.96 12.4584 130.973 12.4264
          131 12.3854Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M180 14.4944L179.459 14.4274C179.298 14.4824 179.145 14.5554 179
          14.6404L180 14.4944Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M162.203 12.9172C162.658 12.9502 162.823 12.3922 163 12.1492C162.74
          12.3752 162.402 12.4802 162.001 12.5152C161.993 12.6222 162.052
          12.7512 162.203 12.9172Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M136 12.4544L135 12.6134C135.26 12.5714 135.699 12.5024 136
          12.4544Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M195.337 14.4139C195.392 14.5039 195.522 14.5929 195.663
          14.6539C195.671 14.5839 195.542 14.5009 195.337 14.4139Z"
          fill="#00EEFF"></path>
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="60"
          y="0"
          width="1"
          height="1">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M60.2152 0.491885H60.7152V0.991513H60.2152V0.491885Z"
            fill="white"></path>
        </mask>
        <g mask="url(#mask0)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M60.417 0.717749C60.4035 0.738749 60.426 0.749749 60.4225
            0.767749C60.533 0.730749 60.567 0.707749 60.417 0.717749Z"
            fill="#00EEFF"></path>
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M81.8955 2.42294L81.2765 2.38494C81.1975 2.48394 81.1175 2.57994
          81.1045 2.68294L81.8955 2.42294Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M281.777 43.7127C281.724 43.6697 281.706 43.6537 281.657
          43.6137C280.871 43.1287 281.301 43.4227 281.777 43.7127Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M176.545 89.0339C177.46 89.4131 175.06 89.6283 176.437
          90.0339C176.309 89.8576 177.684 89.2583 176.545 89.0339Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M130 12.5959C130.33 12.5549 130.665 12.5139 131 12.4719C130.543
          12.5079 130.237 12.5469 130 12.5959Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M174.212 90.5084V90.5134L174.788 90.5594C174.55 90.5384 174.415
          90.5264 174.212 90.5084Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M290.112 69.7098C290.299 69.6778 290.589 69.5268 290.887
          69.3628C290.829 69.3378 290.642 69.4058 290.112 69.7098Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M289 49.0339C289.344 49.3573 289.678 49.6906 290 50.0339C289.631
          49.6125 289.301 49.2991 289 49.0339Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M277 77.1449C276.625 77.4089 276.487 77.4389 276 77.9229C276.052
          77.9119 276.101 77.8919 276.164 77.8609C276.332 77.6399 276.566
          77.4079 277 77.1449Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M261 82.6637C261.11 82.6717 261.253 82.6587 261.379 82.6517C261.573
          82.5737 261.8 82.4817 262 82.4017L261 82.6637Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 13.0339C14.2764 12.867 14.462 12.8072 14.6139 12.7819C14.3615
          12.73 15.9822 10.9716 14 13.0339Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M130.167 12.6914C130.325 12.5354 130.564 12.4444 131
          12.3764C130.673 12.4154 130.359 12.4524 130.052 12.4884C129.975
          12.5794 129.962 12.6594 130.167 12.6914Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M277 80.0339C276.669 80.3933 276.334 80.7078 276 81.0339C276.282
          80.777 276.648 80.4321 277 80.0339Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M70 82.6123L69 82.4553C69.3785 82.5593 69.7338 82.6163 70 82.6123Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M269 82.2159C268.728 82.2559 268.446 82.3919 268.159
          82.5699C268.102 82.6629 268.035 82.7559 268 82.8519C268.334 82.6589
          268.666 82.4269 269 82.2159Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.507 14.5329C13.495 14.5369 13.493 14.5339 13.493 14.5339C13.493
          14.5339 13.495 14.5369 13.507 14.5329Z"
          fill="#00EEFF"></path>
        <mask
          id="mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="300"
          height="92">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0.479004H299.659V91.8504H0V0.479004Z"
            fill="white"></path>
        </mask>
        <g mask="url(#mask1)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.7437 43.8416C4.05566 44.4921 4.09873 44.7355 4.08676
            44.8502C3.95614 44.5349 3.78054 44.1754 3.58198 43.691C3.56523
            43.675 3.53987 43.6386 3.52217 43.6225C3.59346 43.6692 3.66523
            43.7255 3.7437 43.8416ZM7.2126 53.3444C6.84562 52.8858 6.41547
            52.2921 5.95279 51.6708C5.93318 51.4745 6.10686 51.5741 6.39633
            51.9618C6.19538 51.783 6.81164 52.742 7.2126 53.3444ZM4.42408
            49.3939C4.42121 49.3953 4.4193 49.3968 4.4193 49.3968C4.16236
            48.9697 3.9437 48.6059 3.80447 48.3737C3.93318 48.5864 4.11212
            48.8804 4.42408 49.3939ZM29.7805 7.06598C27.5136 7.94966 28.8408
            7.81266 28.4346 8.19353C25.7844 9.34974 27.0906 8.4306 26.1102
            8.63658C24.4887 9.46293 23.3351 10.2854 22.1188 10.8611C22.004
            10.8509 21.759 10.9461 22.0049 10.745C21.2059 11.2978 19.925 11.8963
            18.6293 12.573C17.3322 13.2454 16.0327 14.0178 15.1576
            14.7392C14.9978 14.7946 14.9121 14.8145 14.8624 14.8131C15.2624
            14.7781 15.403 14.9136 15.4035 15.0608C14.4767 15.6297 13.8088
            16.3088 13.3475 16.3453C13.9045 15.9795 13.9035 15.8736 14.1308
            15.6467C13.8231 15.8721 13.526 16.1155 13.2279 16.354C12.7083
            16.4983 13.0169 16.2583 13.4404 15.9542C13.8614 15.6467 14.4155
            15.3115 14.3494 15.2012C12.4925 16.4662 10.5523 18.2302 9.20303
            19.2839C8.96475 19.2815 9.6992 18.5834 10.2992 18.1087C10.8882
            17.621 11.293 17.2829 10.2954 17.8746C11.0188 17.3494 11.7729
            16.8767 12.5226 16.4041C11.5418 16.7679 11.8786 16.5488 12.2303
            16.3035C12.5868 16.0718 12.9619 15.8216 12.0255 16.1126C10.5518
            17.0968 9.7882 17.7454 9.05136 18.4182C8.68916 18.7636 8.30734
            19.0813 7.88341 19.5064C7.45088 19.9198 6.93892 20.3901 6.30782
            21.0313C5.88007 21.7382 6.68389 21.0294 6.26045 21.7314C5.87432
            22.2337 5.54322 22.6524 5.2437 23.0012C4.96332 23.3675 4.71786
            23.6668 4.49394 23.9165C4.03796 24.4101 3.73413 24.7613 3.41404
            25.0528C3.09394 25.3447 2.83365 25.6338 2.54562 26.0637C2.40447
            26.2804 2.24466 26.5252 2.0992 26.841C1.96141 27.1616 1.81739
            27.5406 1.66523 27.9972C1.54849 28.4884 1.60303 28.8819 1.49777
            29.4308C1.44753 29.7068 1.36523 30.0254 1.26619 30.4413C1.15901
            30.8542 0.983893 31.3473 0.803032 31.9949C0.754706 31.6718 0.681501
            31.3356 0.658056 31.0082C0.207338 32.7348 0.318821 33.275 0.339395
            33.804C0.344659 34.0692 0.386285 34.3413 0.365233 34.7712C0.334611
            35.2017 0.314037 35.7924 0.246572 36.714C0.184371 36.389 0.141309
            35.6928 0.15997 35.1468C0.184371 34.6007 0.210687 34.2033 0.167147
            34.4691C-0.0912266 35.6588 0.00877336 36.0989 0.0795868
            36.4516C0.153271 36.8077 0.314037 37.0851 0.35662 37.9974C0.255185
            38.133 0.167147 38.2685 0.0719313 38.407C0.28485 40.2899 0.807338
            42.2132 1.55566 44.0695C2.30255 45.9286 3.26858 47.7237 4.3437
            49.4361C4.14944 49.2952 3.70829 48.7434 4.10542 49.6207C6.90973
            53.9366 10.7815 58.2437 14.4839 61.4621C15.0308 61.775 15.5748
            62.0859 16.126 62.3822C16.471 62.6426 16.6954 62.9652 16.2868
            62.7296C17.7523 63.9499 16.4681 62.6455 17.2691 63.0915C18.1585
            63.6949 18.3413 64.0471 17.7337 63.723C19.1705 64.5805 21.2451
            65.7887 23.3648 66.8477C24.4217 67.3908 25.4561 67.9864 26.4418
            68.5028C27.4298 69.0168 28.3346 69.5187 29.0782 69.9807L28.4351
            69.7504C29.0289 70.0822 29.5116 70.3023 29.9356 70.466C30.3633
            70.6219 30.736 70.7108 31.0978 70.8089C31.8226 71.0018 32.5136
            71.1995 33.6002 71.8442L33.1432 71.6897C34.4557 72.3533 35.86
            72.8989 37.2442 73.4862C38.6241 74.0842 40.0413 74.5758 41.4011
            75.0976C42.7576 75.631 44.0944 76.0833 45.3399 76.5487C45.9619
            76.7809 46.5628 77.0126 47.136 77.2444C47.7131 77.4644 48.2662
            77.6758 48.7849 77.8934C49.2514 77.8191 50.6203 78.3015 51.9466
            78.7202C53.2786 79.1186 54.5604 79.4815 54.8145 79.2551C56.3848
            79.855 56.9456 80.0698 57.6667 80.3452C58.392 80.6046 59.2767
            80.9389 61.5504 81.5626C62.7719 81.8687 63.4561 81.6311 64.982
            81.9182L64.9571 82.2199L67.0839 82.3424L68.1126 82.9205L69.5542
            83.1405C68.9892 83.0055 68.3719 82.7587 68.4753 82.6916C70.7255
            83.023 73.8805 83.9319 73.9092 84.1039L79.2518 84.7587C79.115
            84.8632 78.2203 84.7543 77.5064 84.7087C80.3853 84.8865 77.9715
            85.1109 80.6126 85.2484C80.2743 85.127 80.8274 85.1017 80.8959
            85.0512C81.5293 85.2397 82.5829 85.4578 83.7537 85.6443C84.9246
            85.8265 86.2112 85.9927 87.3083 86.1117L86.8619 86.256C88.8317
            86.5645 90.9164 86.8117 93.0633 87.0575C95.2116 87.2849 97.4198
            87.5132 99.6384 87.7824C98.0576 87.4952 98.4648 87.1838 99.7681
            87.3063L100.253 87.6828C101.115 87.59 98.9088 87.401 100.659
            87.3709C102.31 87.606 101.733 87.862 100.77 87.8499L102.797
            87.9781C102.905 88.0831 102.426 88.087 102.291 88.1895C103.139
            88.0845 105.743 88.8676 106.973 88.6529L107.077 88.7583C109.299
            88.9896 110.917 89.1319 112.431 89.2577C113.948 89.3602 115.362
            89.452 117.175 89.6017C117.269 89.3355 118.681 89.5191 119.779
            89.3928C122.432 89.7324 125.379 90.1249 128.593 90.3348C130.859
            90.3576 129.268 90.1502 130.293 90.0598C130.443 90.3221 133.654
            90.5174 131.978 90.583C135.839 90.7613 136.912 90.8021 141.802
            90.8643C141.277 90.7341 140.683 90.6578 140.996 90.5898C141.882
            90.6019 143.006 90.7948 142.873 90.9051L142.639 90.9221C145.387
            90.838 148.542 91.1368 151.562 91.4123C154.582 91.662 157.469
            91.8495 159.657 91.6406L159.412 91.661C160.924 91.6022 162.435
            91.5512 163.946 91.457C166.029 91.5089 162.693 91.6386 164.235
            91.799C169.125 91.3972 171.959 91.9209 175.982 91.5711C177.544
            91.9122 180.31 91.8883 183.21 91.7747C186.11 91.6454 189.142 91.4793
            191.253 91.6527L191.004 91.4871C192.085 91.2884 192.322 91.5493
            193.125 91.5502C193.006 91.421 191.94 91.4482 192.611
            91.3238C201.802 90.7423 211.608 90.6325 221.038 89.9903C224.487
            89.7474 222.914 88.9245 227.285 88.9322L226.084 89.3685C227.81
            89.1081 229.56 88.8944 231.319 88.7054C233.078 88.5086 234.844
            88.3114 236.606 88.1146C238.367 87.9174 240.124 87.7202 241.864
            87.5254C243.6 87.2927 245.316 87.0318 246.997 86.7345C246.603
            86.8603 246.604 86.957 245.943 87.0405C247.401 87.0886 251.232
            86.3726 251.231 86.1812C252.288 86.066 251.497 86.323 252.16
            86.3298C253.62 86.2691 255.726 85.5059 256.523 85.604C256.659 85.62
            256.131 85.7444 255.871 85.8051C256.791 85.5574 260.233 85.0784
            259.291 84.9734C261.852 84.6305 264.104 83.8862 266.142
            83.5505C267.375 82.9467 270.462 82.5474 270.751 81.9974C270.725
            82.2403 271.837 81.9955 273.139 81.5743C273.787 81.3552 274.483
            81.0982 275.106 80.8407C275.727 80.574 276.279 80.3209 276.643
            80.1252C276.18 80.4579 278.019 79.7117 277.489 80.1266C279.238
            79.4183 280.767 78.5866 280.373 78.523C282.061 78.0372 283.561
            77.0874 284.865 76.2256C286.163 75.3405 287.262 74.5302 288.251
            74.2139C290.48 72.4461 292.478 70.5422 294.165 68.4645C295.856
            66.3911 297.258 64.1763 298.361 61.8634C298.437 61.9542 298.315
            62.3176 298.097 62.8136C297.866 63.3028 297.508 63.9052 297.168
            64.5028C296.791 65.077 296.431 65.6478 296.178 66.0675C295.913
            66.479 295.779 66.7598 295.902 66.785C297.3 64.7525 298.137 62.9827
            298.876 61.2085L299.133 60.5323C299.267 60.2015 299.401 59.8692
            299.536 59.534L299.64 59.2775C299.644 59.2566 299.669 59.2342
            299.655 59.2148L299.616 59.1555L299.537 59.0385C299.432 58.8825
            299.327 58.7266 299.221 58.5692C299.05 58.3156 298.878 58.0591
            298.703 57.7982C298.419 57.3853 298.148 56.9451 297.837
            56.504C297.528 56.0624 297.21 55.599 296.879 55.1049C296.22 54.0614
            297.431 55.5645 297.732 55.8647C296.314 53.9244 294.856 52.1231
            293.401 50.4087C292.648 49.5736 291.923 48.7351 291.14
            47.9748C290.38 47.1907 289.582 46.4693 288.774 45.7814C288.809
            45.7596 289.17 46.0443 289.505 46.3202C288.894 45.7469 288.138
            44.9274 288.071 45.1465C288.287 45.3107 288.496 45.4856 288.703
            45.6614C289.081 46.2381 288.403 45.746 287.554 45.1849C286.716
            44.6082 285.75 43.9116 285.637 43.9325C286.358 44.1637 285.584
            43.4719 284.897 42.8292C284.219 42.1782 283.584 41.6288 284.696
            42.0301C284.24 41.7206 283.639 41.3728 283.659 41.5554C283.041
            41.0259 282.882 40.8039 283.625 41.1911C282.419 40.3065 281.966
            40.1131 281.573 39.8979C281.171 39.6953 280.826 39.4738 279.709
            38.7203C279.77 38.6455 280.24 38.9219 280.534 38.986C279.916 38.6776
            279.3 38.3696 278.608 37.9707C277.907 37.5864 277.128 37.1157
            276.187 36.4769C276.332 36.5012 276.15 36.2889 276.722
            36.6561C273.378 34.6172 270.756 33.7112 267.003 31.6917C268.317
            31.8511 265.749 30.947 265.692 30.52L263.55 29.6761L264.323
            30.213C263.408 29.7942 262.514 29.4794 261.654 29.1782C260.799
            28.8649 259.973 28.5778 259.193 28.2051L260.633 28.6176C259.458
            28.1765 258.288 27.7237 257.104 27.3137C257.753 27.6057 258.401
            27.8962 259.041 28.2056C257.089 27.6372 259.085 28.7026 256.631
            27.6644C256.891 27.7422 256.423 27.5653 255.777 27.2977C255.128
            27.0416 254.298 26.6987 253.841 26.4227L253.848 26.4242C252.876
            26.207 251.875 25.9967 250.497 25.4521C250.165 25.2248 250.912
            25.164 250.697 25.0144C249.678 24.9974 246.196 23.6838 244.914
            23.677C245.33 23.7178 245.402 23.5405 245.282 23.4662L244.428
            23.4768C243.559 23.1436 243.437 23.0775 243.622 22.9692C240.942
            22.1933 244.358 23.6444 241.217 22.5868C241.473 22.6335 241.362
            22.5669 241.893 22.6723C238.708 21.7109 235.639 21.2397 232.691
            20.4576C231.89 19.9013 235.311 20.956 234.372 20.4289C232.219
            19.9616 229.966 19.6128 227.737 19.2475C226.621 19.0628 225.513
            18.8748 224.424 18.6742C223.331 18.4857 222.255 18.3031 221.215
            18.0869L221.986 18.27C221.316 18.6703 218.917 17.4466 216.759
            17.296C217.283 17.3538 217.486 17.1391 217.337 17.1697C215.91
            16.8714 214.431 16.6824 212.767 16.4706C211.102 16.2671 209.252
            16.0407 207.054 15.8332L207.614 15.7779C205.929 15.4276 203.148
            15.0739 201.316 14.7781C200.753 14.8451 199.704 14.6994 200.32
            14.9661C194.728 13.5208 189.452 14.1475 184.456 13.1322C185.061
            13.1759 185.907 13.1244 185.426 13.088L181.109 12.8878C181.434
            13.137 181.664 13.0651 182.557 13.3071C182.002 13.3994 180.781
            13.4115 179.715 13.3318C181.659 13.3314 179.723 12.9636 178.893
            12.7907C179.029 12.8669 178.737 12.9272 178.804 13.001C176.575
            12.4827 178.4 13.4314 175.343 13L175.512 12.8907C174.359 12.8898
            173.292 12.9894 172.054 12.8898C171.551 12.6027 173.283 12.8334
            173.372 12.6245C171.628 12.5303 169.914 12.9753 168.346
            12.6619C169.088 12.6129 170.153 12.6454 170.897 12.5973C169.189
            12.2383 168.985 12.3092 167.71 12.2203L167.835 12.231L164.883
            11.9998L165.635 12.1057C164.535 12.4011 163.423 12.4156 162.217
            12.4098C161.011 12.3996 159.71 12.3588 158.239 12.5653C161.099
            12.1747 158.714 12.3272 158.934 12.0352C158.182 11.9459 157.039
            11.8429 157.365 11.7632C155.974 11.9823 155.04 11.5932 153.078
            11.9546C152.989 11.8608 151.589 11.9619 151.9 11.7204C150.091
            11.8152 152.33 11.8803 152.179 12.0012C149.398 12.3233 145.615
            11.7593 141.619 12.3306C138.173 12.6935 137.552 13.0525 134.363
            13.3829C133.273 13.2906 135.226 12.9908 135.226 12.9908C133.474
            12.9306 132.166 13.0919 130.533 13.3134C130.859 13.206 130.512
            13.1623 130.004 13.0943C128.688 13.2187 129.149 13.5048 127.401
            13.4975C127.691 13.3697 128.059 13.172 128.687 13.018C127.914
            13.1205 127.147 13.2449 126.375 13.3697C125.603 13.5111 124.826
            13.653 124.027 13.7739C123.587 13.6515 124.972 13.43 125.529
            13.2672C123.731 13.5179 120.869 13.873 120.339 14.1873L121.504
            14.1674C120.849 14.2442 121.144 13.977 121.624 13.8823C123.15
            13.5271 123.861 13.7545 123.957 13.841L122.821 14.0129C123.433
            14.232 124.576 14.098 125.855 13.8847C127.135 13.6753 128.554
            13.4314 129.707 13.3547L128.778 13.8308L131.114 13.3173C131.624
            13.3838 131.982 13.583 130.851 13.7292C132.513 13.8031 132.301
            13.4752 133.732 13.5898C133.89 13.6126 133.821 13.6374 133.677
            13.6641C134.212 13.5825 134.747 13.5223 135.282 13.4504C134.971
            13.5524 135.568 13.7205 134.574 13.7161C137.48 13.7919 139.225
            13.1599 140.329 13.4067L140.089 13.4446C142.092 13.2614 142.188
            13.2094 142.155 13.1035C142.123 13.0034 141.963 12.882 143.466
            12.6585L145.521 13.3236L145.907 13.0141C146.322 13.0112 147.143
            13.0064 146.995 13.1302C148.532 12.8767 146.728 13.0098 147.619
            12.7887C148.621 12.8805 149.996 12.8315 151.181 12.8159C152.364
            12.796 153.355 12.8043 153.581 13.0379C154.296 12.8043 151.669
            12.8854 153.801 12.7047C154.412 13.2157 156.266 12.5653 158.092
            12.8349C157.53 13.0919 155.803 12.9349 157.627 13.1881C158.73
            12.7557 161.178 13.3212 162.715 13.2522C162.753 13.0821 163.591
            13.0341 164.74 13.0656C165.891 13.0948 167.352 13.1633 168.645
            13.1453C168.561 13.2021 169.773 13.2886 171.305 13.3872C172.837
            13.5038 174.686 13.6632 175.881 13.7875L175.807 13.8429C176.048
            13.6768 176.787 13.6437 177.691 13.6549C178.142 13.6671 178.635
            13.6802 179.129 13.6933C179.621 13.7166 180.114 13.7404 180.564
            13.7618C180.48 13.9697 180.15 14.0295 179.171 14.0523L180.86
            14.1767C181.267 14.0926 181.799 14.0717 182.898 14.113L182.454
            14.3472C183.578 14.4234 184.716 14.4302 185.934 14.4662C187.151
            14.4973 188.444 14.6139 189.881 14.8349C190.068 14.6027 187.642
            13.9236 190.718 14.0528C190.82 14.1975 190.911 14.4375 190.105
            14.4152C190.369 14.4234 191.051 14.3943 191.573 14.5021L190.331
            14.61C191.638 14.8354 191.037 14.4881 192.089 14.6105C192.049 14.799
            192.854 14.8213 192.688 14.9583C192.417 14.951 191.778 14.7912
            191.368 14.8271C192.55 14.9782 193.974 15.2206 194.431
            15.4232C194.34 15.3023 194.458 15.1662 195.327 15.244C197.57 15.4942
            196.07 15.5636 197.365 15.8158C197.923 15.7473 199.335 16.0533
            199.407 15.773C200.062 15.8517 200.167 15.9989 200.411
            16.1058C201.589 16.0897 205.817 16.8107 204.556 16.3142L204.261
            16.253C204.317 16.2569 204.384 16.2617 204.452 16.2671L204.443
            16.2656L204.452 16.2671C206.366 16.4021 208.337 16.6819 210.286
            17.0667C212.237 17.4442 214.18 17.8411 216.065 18.084L216.044
            18.1772C218.296 18.458 221.019 19.0216 222.652 19.1071C223.312
            19.229 224.177 19.5661 223.873 19.6264C225.585 19.9023 225.797
            19.8392 228.218 20.4998C226.479 20.3114 228.778 20.767 226.446
            20.2589C228.635 20.7967 229.103 21.0459 231.523 21.2844C230.67
            21.3481 233.112 21.9509 234.169 22.1219L232.669 21.6016C233.852
            21.8237 234.87 22.0928 235.905 22.3551L235.27 22.5378C239.646
            23.9694 243.481 23.9247 246.661 25.5604C247.891 25.6877 244.312
            24.6952 245.426 24.7526C246.438 25.0659 247.435 25.5109 248.26
            25.8835C249.081 26.2726 249.727 26.601 250.042 26.7016C252.732
            27.7776 252.754 27.097 255.571 28.2348C255.246 27.9962 255.004
            27.5683 257.17 28.2688C259.144 29.064 259.424 29.6752 258.153
            29.2608C257.679 29.0796 257.46 28.9659 257.494 28.9348C256.839
            28.7332 255.984 28.4641 256.724 28.876L257.021 28.8649C257.967
            29.3341 260.664 30.418 259.962 30.3344C261.472 30.8542 260.667
            30.4136 259.589 29.9186C262.034 31.1039 263.5 31.5499 265.974
            32.8479C265.6 32.6007 265.286 32.3029 265.685 32.4928C267.511
            33.4236 267.921 33.6068 268.216 33.6558C268.503 33.72 268.667
            33.6602 269.907 34.2913C271.219 35.1934 269.222 34.3879 271.745
            35.5898C273.172 36.5332 271.913 36.0343 271.281 35.7856C273.605
            36.8257 275.309 38.2841 276.49 39.1085L275.322 38.4696C276.02 38.852
            276.568 39.2051 277.126 39.5632L276.608 38.9588C277.25 39.4092
            277.799 39.7803 277.733 39.8556C278.482 40.185 277.428 39.1852
            278.87 39.9202C279.683 40.5877 281.541 41.6822 281.657
            42.1379C279.059 40.1097 281.771 42.5975 279.498 41.0007C279.951
            41.2858 280.479 41.672 281.09 42.1666C281.165 42.2633 280.963 42.152
            280.781 42.0408C281.962 43.0794 282.277 43.2543 282.651
            43.3825C283.021 43.5152 283.426 43.6308 284.671 44.6845C284.241
            44.5582 285.055 45.4069 285.463 45.7426C284.481 44.8778 285.178
            45.1018 286.165 45.8096C286.906 46.5534 287.339 46.9202 287.682
            47.2043C288.03 47.4832 288.298 47.6654 288.69 48.0516L287.827
            47.1368C288.245 47.3107 289.011 48.0341 290.057 49.064C290.049
            49.2481 289.066 48.1721 289.389 48.5898C290.054 49.0737 290.664
            49.7699 291.253 50.587C291.554 50.9897 291.865 51.4085 292.204
            51.8233C292.53 52.2504 292.881 52.6764 293.282 53.0796C293.859
            53.9638 293.107 53.3585 293.618 54.1256C295.328 55.9969 295.095
            56.4351 296.715 58.5303C296.603 58.1446 296.517 57.9299 296.609
            57.994C296.655 58.0261 296.745 58.1281 296.898 58.3127C296.975
            58.4055 297.068 58.5187 297.18 58.6542C297.235 58.7222 297.295
            58.7956 297.36 58.8747C297.421 58.9481 297.51 59.0623 297.539
            59.0827L296.945 58.2296C296.737 57.9279 296.53 57.6277 296.304
            57.3396C295.86 56.7596 295.429 56.1703 294.976 55.5995C295.613
            56.2903 296.24 56.9957 296.864 57.7093C296.112 56.6124 295.156
            55.4892 294.689 54.847C295.652 55.8011 295.525 55.7292 295.29
            55.1054C295.703 55.9366 296.239 56.6571 296.837 57.3853C297.125
            57.7569 297.407 58.1441 297.686 58.5517L297.883 58.8495L298.031
            59.0914C298.078 59.1721 298.123 59.2537 298.164 59.3343C298.138
            59.4198 298.071 59.5082 298.024 59.5913C297.835 59.5524 297.642
            59.5204 297.447 59.4961L297.385 59.5826L297.357 59.6234C297.348
            59.637 297.344 59.6409 297.317 59.6948C297.235 59.8638 297.154
            60.031 297.073 60.1971C296.925 60.5342 296.74 60.8456 296.589
            61.1726C296.289 61.8284 295.955 62.458 295.615 63.1221C294.901
            64.4236 294.072 65.83 292.687 67.2951L293.593 66.3673C293.503
            66.6432 293.118 67.1402 292.631 67.6721C292.133 68.1934 291.569
            68.7846 291.115 69.2592C291.065 69.0299 290.451 69.5687 289.949
            69.8369C290.017 69.8641 289.475 70.3596 288.884 70.8852C288.285
            71.4016 287.66 71.9763 287.615 72.2299C287.076 72.5151 286.552
            72.8153 286.001 73.0704C285.655 73.286 285.353 73.4872 285.082
            73.6781C284.805 73.8573 284.56 74.0274 284.337 74.1911C283.894
            74.5234 283.529 74.8183 283.16 75.101C282.43 75.6801 281.698 76.2475
            280.258 76.9111C280.644 76.6109 280.497 76.5861 280.485
            76.5045C280.473 76.4233 280.601 76.2927 281.498 75.7369C280.573
            76.2305 279.698 76.5866 279.741 76.7192C279.152 76.8951 277.18
            78.1528 276.208 78.3393C275.829 78.5886 275.759 78.8179 274.643
            79.2254C274.508 79.2347 274.615 79.1346 274.615 79.1346C274.648
            79.2381 272.902 79.8725 273.953 79.7336C272.32 80.0081 270.157
            80.6999 268.011 81.3032C265.869 81.9095 263.749 82.4245 262.31
            82.4706C260.066 83.0186 257.333 83.5734 255.259 83.91C255.384
            83.8318 255.251 83.8148 255.647 83.7701C252.758 84.0339 253.968
            84.6033 250.663 84.8457C248.948 84.7534 252.383 84.4561 251.323
            84.4736C250.787 83.9062 247.378 85.0672 245.126 84.9851L245.656
            84.8797C243.945 84.8831 241.572 85.6589 238.804 85.9052C238.804
            85.9052 238.933 85.8348 238.81 85.8085C236.958 86.3303 233.396
            86.8642 230.744 87.2106C231.547 86.886 231.939 86.9657 231.409
            86.7641C230.22 86.9128 231.799 87.1319 229.424 87.4277C228.636
            87.3665 226.907 87.4122 226.656 87.1673L228.899 86.9395C227.717
            86.7039 225.995 87.2271 224.806 87.2795L224.944 87.1144C221.51
            87.4977 221.493 87.8654 217.786 88.1297L218.178 88.2137C216.182
            88.7491 216.465 88.1346 214.466 88.5732L213.828 88.2385C212.501
            88.4552 209.172 88.9235 206.777 89.147C208.119 88.7748 210.915
            88.4707 212.638 88.1754C211.314 88.274 208.139 88.4868 207.46
            88.7229C207.992 88.6529 208.659 88.5174 209.19 88.5441C207.583
            88.9454 205.451 89.2252 202.809 89.3821C203.24 88.6408 195.909
            89.5123 193.97 89.027C191.639 89.2888 189.318 89.2869 186.901
            89.3102C184.484 89.3204 181.971 89.3204 179.248 89.3578C181.189
            89.9126 177.658 89.3938 178 89.9835C176.731 90.037 176.246 90.0336
            176.133 89.9961L172.282 89.6886C171.37 89.521 173.489 89.5463
            173.371 89.4108C170.593 89.3908 172.204 89.1645 170.367
            89.0071C170.744 89.2213 169.402 89.3073 167.818 89.2194L170.048
            89.5031C166.163 89.8023 166.694 88.9901 162.78 89.2864L164.019
            89.1047C162.009 89.2305 156.616 88.9633 154.636 89.4127C154.283
            89.3364 153.817 89.1645 154.703 89.1402C152.304 89.0736 149.633
            89.0756 147.093 89.0862C144.554 89.0892 142.147 89.0853 140.291
            88.8225L140.661 88.6971C139.766 88.6908 139.092 88.8856 137.979
            88.6796C138.04 88.6228 138.347 88.5543 137.933 88.5237C137.458
            88.548 135.544 88.65 134.364 88.5018L135.385 88.393C134.22 88.2813
            133.187 88.2322 132.232 88.2035C131.279 88.1666 130.403 88.1637
            129.549 88.172C127.843 88.188 126.227 88.2468 124.264
            88.1589C124.284 87.9436 123.378 87.7449 122.239 87.5584C121.101
            87.3621 119.73 87.1907 118.817 87.077L118.886 87.0221C116.174
            86.7524 113.86 86.6645 111.595 86.5504C109.332 86.4328 107.115
            86.3478 104.594 86.171C103.057 85.7959 100.429 85.417 97.5604
            85.0502C96.1265 84.8661 94.6332 84.6742 93.1868 84.4881C91.7418
            84.286 90.348 84.0616 89.1088 83.8328C89.8614 84.0106 88.9408
            84.1486 88.0475 84.0689C86.8906 83.8148 84.7734 83.8979 85.0136
            83.536L85.4293 83.6035C84.6002 83.1051 82.1892 82.8369 80.3203
            82.5304L79.9327 82.989C77.5427 82.2238 74.3365 81.7477 71.4126
            81.2614C69.9466 81.038 68.5604 80.7747 67.3767 80.524C66.1935
            80.2723 65.2183 80.0052 64.5982 79.6845C63.4083 79.5412 61.9638
            79.2191 60.7834 79.0681L60.9078 78.8159C57.7188 77.9512 56.1265
            77.5198 52.1848 76.4515L53.0389 76.4884C50.838 75.8321 50.0375
            76.4029 47.9915 75.7976C47.8274 75.6067 47.6657 75.408 47.5059
            75.2079C45.6059 74.5506 43.7045 73.8588 41.8447 73.081C40.9116
            72.7011 39.9686 72.3523 39.0327 71.9972C38.1011 71.63 37.1628
            71.2904 36.2102 70.9955C36.2935 70.9649 36.448 70.9008 37.0542
            71.1349C36.6217 70.945 35.9872 70.6656 35.2892 70.3586C34.5982
            70.0346 33.8518 69.6649 33.1667 69.3545C31.7997 68.7297 30.6935
            68.3042 30.8868 68.7015C30.9423 68.5252 30.0231 68.0496 28.8365
            67.5138C28.2432 67.2456 27.5829 66.9619 26.9447 66.6932C26.3169
            66.4032 25.7131 66.1248 25.2198 65.8965L25.6418 65.8232C24.7609
            65.5297 24.2944 65.3952 23.7628 65.1887C23.2313 64.9832 22.6289
            64.7126 21.5155 64.0864C21.0217 63.7303 21.1753 63.6249 21.825
            64.101C20.1959 62.9098 20.3461 63.5005 18.4116 61.9824L19.2528
            62.3652C17.5691 61.1561 17.6633 61.3125 16.627 60.7864C16.3533
            60.6057 16.0222 60.3608 15.6825 60.0858C15.3485 59.8041 15.003
            59.4941 14.6896 59.1964C14.0758 58.5842 13.5877 58.0241 13.56
            57.8244C13.2557 57.7511 12.2786 57.1278 11.4571 56.2077C11.4045
            56.0255 11.7571 56.3015 11.9399 56.4346C10.7724 55.1472 9.84753
            54.2543 9.05231 53.4139C8.25375 52.5788 7.57719 51.8034 6.98629
            50.756C7.02935 50.6628 6.97719 50.5093 6.88676 50.3319C8.43892
            52.5967 9.96619 54.2169 9.79633 54.0867C9.97576 53.9152 8.57576
            52.2212 9.97193 53.3201C9.32504 52.7201 8.44944 51.8962 7.62648
            50.9567C6.80542 50.0186 6.02217 48.9756 5.5394 48.0127C5.50734
            47.9102 5.47624 47.8106 5.46954 47.7242L5.60542 47.901C5.3705
            47.4657 5.14418 47.0887 4.91595 46.7385C4.62313 46.1205 4.48581
            45.7086 4.20542 45.1198C4.24418 44.9629 4.02935 44.378 3.7638
            43.7046C3.63987 43.3646 3.50734 43.0007 3.38198 42.6577C3.27528
            42.3104 3.17624 41.9844 3.09777 41.7269C2.85231 41.8955 2.53796
            40.4979 2.66715 41.8523C2.63557 41.6803 2.75758 42.0422 2.97624
            42.7364C3.03461 42.8904 3.10734 43.0711 3.15758 43.1683C3.14466
            43.1503 3.12791 43.129 3.11021 43.1056C3.35327 43.7828 3.69107
            44.6607 4.15279 45.6255C3.65566 44.9041 3.20303 44.0904 2.9437
            42.8899C2.61451 42.4075 2.25423 41.8562 2.09011 41.5307C2.08389
            40.7388 1.98485 39.6263 2.01021 38.6576C2.01404 38.4167 2.01834
            38.1845 2.02169 37.9683C2.03413 37.7526 2.05758 37.5534 2.07576
            37.379C2.11499 37.0297 2.15614 36.7771 2.20973 36.6775C2.09155
            35.0423 2.29585 33.1049 2.71739 31.3658C2.91117 30.4894 3.18772
            29.6776 3.40542 28.9358C3.66858 28.2139 3.85662 27.5508 4.02504
            27.0353C4.43078 26.1585 4.70064 26.1575 4.96236 25.9073C4.68724
            26.3829 4.38198 26.9566 4.29729 27.2471C4.40255 27.0353 4.51978
            26.7983 4.64562 26.5442C4.7748 26.2916 4.94657 26.0419 5.10638
            25.7752C5.41978 25.2359 5.81547 24.7098 6.1705 24.208C6.90064
            23.2198 7.57193 22.4047 7.6126 22.1205C8.5126 21.3034 8.3303 21.6104
            8.21164 21.811C8.10207 22.0214 8.00686 22.0884 9.20208
            20.9429L8.94944 21.3286C10.2198 20.3172 11.3791 19.4219 12.4944
            18.5571C13.6002 17.6812 14.6882 16.8729 15.8093 16.0606C16.9447
            15.2717 18.1231 14.4905 19.4652 13.7768C20.1341 13.4164 20.8528
            13.0894 21.6078 12.7528C22.3695 12.4307 23.1571 12.0722 24.0375
            11.804C23.5547 11.9648 23.315 12.0488 23.1982 12.0425C27.0557 10.454
            31.0193 8.81535 35.0724 7.43568C39.1198 6.04774 43.2724 4.99793
            47.3408 4.44023C49.6485 4.10502 49.8533 3.81986 51.2389
            3.44239L51.782 3.65614C52.5681 3.42733 53.3533 3.17569 54.1456
            2.96436C54.9968 2.80405 55.8011 2.72583 56.593 2.68357C56.9887
            2.66219 57.382 2.65005 57.7762 2.64131C58.1719 2.64859 58.5695
            2.65539 58.9743 2.66268C59.7825 2.67628 60.6169 2.68114 61.5193
            2.63208C62.4226 2.59807 63.3959 2.54512 64.4829 2.37994L63.9121
            2.20506C65.4815 2.13364 68.8892 2.01899 68.626 2.23518C68.7863
            2.19971 69.1432 2.02628 69.7557 2.1317L69.8016 2.28667L73.6671
            2.15939C76.6437 2.48731 73.1695 2.73361 77.5026 3.08047C79.1097
            3.19366 82.1303 3.14605 81.5408 2.97505C80.8901 2.89489 79.8877
            2.69426 79.8552 2.53589L81.3447 2.62187C81.6643 2.2036 82.8834
            1.87374 79.2442 1.14795C78.381 1.13969 77.5198 1.13824 76.6571
            1.18002L75.8466 0.900194C76.5671 0.95266 77.2896 0.988124 78.0093
            1.05371C76.8016 0.740851 76.0054 0.878333 75.1289 0.936143C74.9303
            0.804491 74.5743 0.696643 74.0667 0.616C73.216 0.707331 72.3676
            0.838011 71.5227 0.947802C71.2217 0.759797 72.6686 0.635918 70.9743
            0.479004C69.7863 0.638347 72.0963 0.915253 70.1384 0.978893C68.9145
            0.745223 69.2715 0.598997 67.8279 0.771456C67.625 0.655836 67.9303
            0.580536 68.5045 0.527584C67.8595 0.599483 67.0083 0.616 66.2351
            0.64612C65.4624 0.664094 64.7691 0.760769 64.4413 0.885134C63.7973
            0.784087 62.7126 0.844326 61.8045 0.875418C60.8973 0.930313 60.1648
            0.967234 60.2131 0.74668C60.014 0.813235 59.4552 0.941001 58.7992
            1.06537C58.1432 1.18487 57.393 1.31215 56.8083 1.39765C58.9021
            0.651463 52.4107 1.84994 52.9121 1.28835C52.1514 1.6459 50.7274
            1.8888 49.1571 2.19145C47.5944 2.53054 45.8609 2.80988 44.5145
            3.28013C44.6614 3.20872 44.949 3.06687 45.1973 3.03383C43.6356 3.353
            43.1528 3.32143 42.1322 3.46668C42.0126 3.85824 39.6432 4.61074
            38.4169 5.17622C36.4384 5.34479 39.4399 4.42857 38.76
            4.25659C38.8494 4.40088 38.0404 4.66807 37.3188 4.97752C36.5939
            5.27532 35.937 5.54543 36.2906 5.57069C34.7982 5.79464 35.0303
            5.84516 34.1987 5.77278C34.0901 6.29307 32.9049 6.3057 31.8265
            6.98874C30.7676 7.23601 30.5982 7.17772 30.4203 7.17237C30.2408
            7.16412 30.0533 7.21561 28.971 7.7223C27.8633 8.00164 29.7805
            7.06598 29.7805 7.06598Z"
            fill="#00EEFF"></path>
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.47 41.4668C3.396 41.3145 3.338 41.2339 3.251 41.0339C3.298
          41.263 3.328 41.4847 3.402 41.7018C3.521 41.816 3.644 41.9324 3.749
          42.0339C3.656 41.8421 3.558 41.6444 3.47 41.4668Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M205.507 16.5624L205.71 16.6034C205.543 16.5574 205.39 16.5144
          205.29 16.4644C205.424 16.5104 205.421 16.5274 205.507 16.5624Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.9185 81.6384L64.9345 81.4964L64.0655 81.4294L64.9185 81.6384Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M179.85 12.6244C179.75 12.5694 179.55 12.5074 179.149
          12.4434C179.178 12.4814 179.481 12.5494 179.85 12.6244Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M166 12.6854L165 12.3824C165.433 12.5404 165.748 12.6274 166
          12.6854Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M159.677 10.4129C159.448 10.5089 159.383 10.5819 159.323
          10.6499C159.338 10.6519 159.348 10.6529 159.359 10.6549L159.677
          10.4129Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M291 44.0391C291.153 44.2061 291.307 44.3671 291.45 44.5041C292.535
          45.5611 291.816 44.8201 291 44.0391Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M254 25.254C254.332 25.457 254.659 25.657 255 25.91C254.47 25.367
          254.055 24.966 254 25.254Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M275.771 78.4354C275.78 78.4284 275.769 78.4314 275.777
          78.4224C275.584 78.4994 275.414 78.5684 275.222 78.6454L275.771
          78.4354Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M255 27.0339C254.629 26.5652 254.306 26.2606 254 26.0339C254.313
          26.3562 254.677 26.7698 255 27.0339Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M121.98 12.4539L121.114 12.5139C121.132 12.5489 121.084 12.5839
          121.02 12.6139L121.98 12.4539Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M120.976 12.4549L120.024 12.6129C120.464 12.5709 120.846 12.5299
          120.976 12.4549Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M120 12.7014L121 12.3664C120.651 12.4324 120.22 12.4974 120
          12.7014Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M135.37 12.6459L135.63 12.4219C135.483 12.4759 135.392 12.5479
          135.37 12.6459Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M135 12.4403C134.752 12.4953 134.375 12.2163 134.031 12.5153L134
          12.6933C134.159 12.2783 134.768 12.8823 135 12.4403Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M151 12.2463C150.682 12.2253 150.364 12.1553 150.046
          12.1853C150.213 12.3693 150.433 12.6163 150 12.7573C151.204 13.2363
          149.75 12.2653 151 12.2463Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M160.471 12.3154C159.41 12.3834 160.482 12.6304 160.425
          12.7524C161.098 12.5744 161.262 12.5294 160.471 12.3154Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M225.371 20.2421L225 20.6791C227.189 21.1061 225.055 20.4891
          225.371 20.2421Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M236 20.9689L235.349 20.0989L235 20.2939L236 20.9689Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M243.264 25.2789L243 25.0619L244 26.0059L243.264 25.2789Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M265 81.4928L265.427 81.9348C266.213 81.4418 266.297 80.6738 265
          81.4928Z"
          fill="#F7B529"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M219 86.4992C218.803 86.2682 218.303 86.2782 218 86.7182C218.398
          86.5912 218.799 86.6662 219 86.4992Z"
          fill="#00EEFF"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M76.4644 81.6364L77 81.7554L76 81.3124L76.4644 81.6364Z"
          fill="#00EEFF"></path>
      </svg>
      Get in Touch
    </a>
  </h1>
  <div class="calendly__outer">
    <a
      href="https://calendly.com/dennis-dna-pathways/dna-pathways"
      class="calendly"
      target="_blank">
      <button class="button is-rounded">Schedule Meeting</button>
    </a>
  </div>
</div>

<svelte:window bind:innerWidth="{w}" />
