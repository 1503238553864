<style lang="scss">.title {
  font-family: "BrownStd-Regular";
  color: #141414;
}

p {
  font-size: 0.9rem;
}

a {
  color: #4a4a4a;
  padding: 0;
}
a:hover {
  color: #000;
}</style>

<div class="title is-6">Contact</div>
<p>
  <a href="mailto:hello@dnapathways.io">hello@dnapathways.io</a>
</p>
<p>
  <a href="tel:"></a>
</p>
