<script>
  import { scrollTo } from "svelte-scrollto";

  const MESSAGE_STRONG = "Digital and R&D Partner";
  const MESSAGE = `specialising in Design Thinking, Agile Delivery, and Continuous Optimisation.`;
  const offsetScroll = -55;

  let w,
    isMobile = false;

  $: if (w <= 1024) {
    isMobile = true;
  } else {
    isMobile = false;
  }
</script>

<style lang="scss">p {
  font-family: "BrownStd-Light";
  text-align: left;
  color: #ffffff;
  margin-top: 1rem;
  white-space: pre-line;
  font-size: 0.9rem;
}

p.is-mobile {
  padding-left: 10vw;
  padding-right: 10vw;
  margin-top: 10%;
  max-width: 34rem;
  text-align: center;
}

hr {
  border: 1px solid #ffffff;
}

.horizontal-rule-columns {
  margin-bottom: 0;
}

.message-explore {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin-top: 60px;
}

.message-explore > span {
  margin-left: 1.5rem;
  color: #ffffff;
}

.message-explore > a > svg {
  fill: #fe5832;
}

.button {
  border-radius: 50%;
  border-width: 2px;
  width: 42px;
  height: 42px;
  background-color: #141414;
}

svg {
  position: absolute;
  top: 14px;
  left: 9px;
}
svg > path {
  fill: #fe5832;
}

.column.column-message {
  max-width: 16rem;
}

.columns.columns-message {
  margin-left: 15%;
  margin-right: 15%;
}

strong {
  color: #fe5832;
}

.mobile-explore {
  display: flex;
  align-items: center;
  flex-direction: column;
}</style>

{#if isMobile}
  <div class="mobile-explore">
    <p class="is-mobile">
      <strong>{MESSAGE_STRONG}</strong>
      {MESSAGE}
    </p>
    <div class="message-explore">
      <a
        on:click="{() => scrollTo({ element: '#why' })}"
        class="button is-primary is-inverted is-outlined">
        <svg
          width="19"
          height="12"
          viewBox="0 0 19 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.06 0.377441L9.9375 7.25494L16.815 0.377441L18.9375
            2.49994L9.9375 11.4999L0.9375 2.49994L3.06 0.377441Z"
            fill="#FF5933"></path>
        </svg>
      </a>
    </div>
  </div>
{:else}
  <div class="columns columns-message">
    <div class="column column-message">
      <p>
        <strong>{MESSAGE_STRONG}</strong>
        {MESSAGE}
      </p>
      <div class="columns horizontal-rule-columns">
        <div class="column is-two-fifths">
          <hr />
        </div>
      </div>
      <div class="message-explore">
        <a
          on:click="{() => scrollTo({ element: '#why', offset: offsetScroll })}"
          class="button is-primary is-inverted is-outlined">
          <svg
            width="19"
            height="12"
            viewBox="0 0 19 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.06 0.377441L9.9375 7.25494L16.815 0.377441L18.9375
              2.49994L9.9375 11.4999L0.9375 2.49994L3.06 0.377441Z"
              fill="#FF5933"></path>
          </svg>
        </a>
        <span>Explore more</span>
      </div>
    </div>
  </div>
{/if}

<svelte:window bind:innerWidth="{w}" />
