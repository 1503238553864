<style lang="scss">.title {
  font-family: "BrownStd-Regular";
  color: #141414;
}

p {
  font-size: 0.9rem;
}</style>

<div class="title is-6">Design for Life.</div>
<p>
  Redesign
  <br />
  how we live,
  <br />
  work and play.
</p>
