<script>
  import HeaderTagline from "./HeaderTagline.svelte";
  import HeaderPolkaLogo from "./HeaderPolkaLogo.svelte";
  import HeaderMobile from "./HeaderMobile.svelte";
  import ParticlesCanvas from "./particles/ParticlesCanvas.svelte";
  import { onMount } from "svelte";

  let w,
    isMobile = false,
    isTablet = false;
  let mounted;

  $: if (w <= 1024) {
    isMobile = true;
    if (w >= 768) {
      isTablet = true;
    } else {
      isTablet = false;
    }
  } else {
    isMobile = false;
    isTablet = false;
  }

  onMount(() => {
    requestAnimationFrame(() => {
      mounted = true;
    });
  });
</script>

<style lang="scss">.header-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.header-mobile-container {
  justify-content: flex-start;
}</style>

<div class="header-container" class:header-mobile-container="{isMobile}">
  {#if isMobile}
    {#if isTablet}
      <HeaderPolkaLogo />
    {/if}
    <HeaderMobile />
  {:else}
    <!-- <HeaderPolkaLogo /> -->
    {#if mounted}
      <ParticlesCanvas />
    {/if}
    <HeaderTagline />
  {/if}
</div>

<svelte:window bind:innerWidth="{w}" />
