<script>
  import { page, params } from "@roxi/routify";
  import { onMount } from "svelte";

  let articleWidth;
  let backToProjects;

  onMount(() => {
    backToProjects = $params && $params.projects;
    history.replaceState({}, $page.title, location.origin + location.pathname);
  });
</script>

<style lang="scss" global>@-webkit-keyframes -global-spinAround {
  :global(from) {
    transform: rotate(0deg);
  }
  :global(to) {
    transform: rotate(359deg);
  }
}
@keyframes -global-spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
:global(.content:not(:last-child)) {
  margin-bottom: 1.5rem;
}

:global(.content) :global(li) + :global(li) {
  margin-top: 0.25em;
}
:global(.content) :global(p:not(:last-child)),
:global(.content) :global(dl:not(:last-child)),
:global(.content) :global(ol:not(:last-child)),
:global(.content) :global(ul:not(:last-child)),
:global(.content) :global(blockquote:not(:last-child)),
:global(.content) :global(pre:not(:last-child)),
:global(.content) :global(table:not(:last-child)) {
  margin-bottom: 1em;
}
:global(.content) :global(h1),
:global(.content) :global(h2),
:global(.content) :global(h3),
:global(.content) :global(h4),
:global(.content) :global(h5),
:global(.content) :global(h6) {
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
}
:global(.content) :global(h1) {
  font-size: 2em;
  margin-bottom: 0.5em;
}
:global(.content) :global(h1:not(:first-child)) {
  margin-top: 1em;
}
:global(.content) :global(h2) {
  font-size: 1.75em;
  margin-bottom: 0.5714em;
}
:global(.content) :global(h2:not(:first-child)) {
  margin-top: 1.1428em;
}
:global(.content) :global(h3) {
  font-size: 1.5em;
  margin-bottom: 0.6666em;
}
:global(.content) :global(h3:not(:first-child)) {
  margin-top: 1.3333em;
}
:global(.content) :global(h4) {
  font-size: 1.25em;
  margin-bottom: 0.8em;
}
:global(.content) :global(h5) {
  font-size: 1.125em;
  margin-bottom: 0.8888em;
}
:global(.content) :global(h6) {
  font-size: 1em;
  margin-bottom: 1em;
}
:global(.content) :global(blockquote) {
  background-color: whitesmoke;
  border-left: 5px solid #dbdbdb;
  padding: 1.25em 1.5em;
}
:global(.content) :global(ol) {
  list-style-position: outside;
  margin-left: 2em;
  margin-top: 1em;
}
:global(.content) :global(ol:not([type])) {
  list-style-type: decimal;
}
:global(.content) :global(ol:not([type]).is-lower-alpha) {
  list-style-type: lower-alpha;
}
:global(.content) :global(ol:not([type]).is-lower-roman) {
  list-style-type: lower-roman;
}
:global(.content) :global(ol:not([type]).is-upper-alpha) {
  list-style-type: upper-alpha;
}
:global(.content) :global(ol:not([type]).is-upper-roman) {
  list-style-type: upper-roman;
}
:global(.content) :global(ul) {
  list-style: disc outside;
  margin-left: 2em;
  margin-top: 1em;
}
:global(.content) :global(ul) :global(ul) {
  list-style-type: circle;
  margin-top: 0.5em;
}
:global(.content) :global(ul) :global(ul) :global(ul) {
  list-style-type: square;
}
:global(.content) :global(dd) {
  margin-left: 2em;
}
:global(.content) :global(figure) {
  margin-left: 2em;
  margin-right: 2em;
  text-align: center;
}
:global(.content) :global(figure:not(:first-child)) {
  margin-top: 2em;
}
:global(.content) :global(figure:not(:last-child)) {
  margin-bottom: 2em;
}
:global(.content) :global(figure) :global(img) {
  display: inline-block;
}
:global(.content) :global(figure) :global(figcaption) {
  font-style: italic;
}
:global(.content) :global(pre) {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  padding: 1.25em 1.5em;
  white-space: pre;
  word-wrap: normal;
}
:global(.content) :global(sup),
:global(.content) :global(sub) {
  font-size: 75%;
}
:global(.content) :global(table) {
  width: 100%;
}
:global(.content) :global(table) :global(td),
:global(.content) :global(table) :global(th) {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}
:global(.content) :global(table) :global(th) {
  color: #363636;
}
:global(.content) :global(table) :global(th:not([align])) {
  text-align: left;
}
:global(.content) :global(table) :global(thead) :global(td),
:global(.content) :global(table) :global(thead) :global(th) {
  border-width: 0 0 2px;
  color: #363636;
}
:global(.content) :global(table) :global(tfoot) :global(td),
:global(.content) :global(table) :global(tfoot) :global(th) {
  border-width: 2px 0 0;
  color: #363636;
}
:global(.content) :global(table) :global(tbody) :global(tr:last-child) :global(td),
:global(.content) :global(table) :global(tbody) :global(tr:last-child) :global(th) {
  border-bottom-width: 0;
}
:global(.content) :global(.tabs) :global(li) + :global(li) {
  margin-top: 0;
}
:global(.content.is-small) {
  font-size: 0.75rem;
}
:global(.content.is-medium) {
  font-size: 1.25rem;
}
:global(.content.is-large) {
  font-size: 1.5rem;
}

:global(.blog) {
  position: relative;
  top: 0;
  min-height: 100vh;
  background-color: #ffffff;
}
:global(.blog) :global(.blog-article__outer) {
  display: flex;
  justify-content: center;
}
:global(.blog) :global(.blog-article__outer) :global(.blog-article.content) {
  margin: 40px;
  width: 800px;
  max-width: calc(100% - 40px);
  letter-spacing: -0.01em;
}
:global(.blog) :global(.blog-article__outer) :global(.blog-article.content) :global(p) {
  font-size: 1.25rem;
}
:global(.blog) :global(.blog-article__outer) :global(.blog-article.content) :global(a) {
  color: #fe5832;
}
:global(.blog) :global(.blog-article__outer) :global(.blog-article.content) :global(strong) {
  color: #363636;
}
:global(.blog) :global(.blog-article__outer) :global(.blog-article.content) :global(.byline) {
  margin-bottom: 60px;
}
:global(.blog) :global(.blog-article__outer) :global(.blog-article.content) :global(.byline) :global(p) {
  text-transform: uppercase;
  font-size: 0.8rem !important;
}
:global(.blog) :global(.blog-article__outer) :global(.blog-article.content) :global(.banner-image) :global(img) {
  width: 100%;
}
:global(.blog) :global(.blog-article__outer) :global(.blog-article.content) :global(.large-image) {
  padding: 15px;
}
:global(.blog) :global(.blog-article__outer) :global(.blog-article.content) :global(.large-image) :global(img) {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
:global(.blog) :global(.home-button__outer) {
  position: relative;
  top: 0;
  z-index: 1;
  pointer-events: none;
}
:global(.blog) :global(.home-button__outer.sticky) {
  position: sticky;
}
:global(.blog) :global(.home-button__outer) :global(.home-button) {
  padding: 50px;
}
:global(.blog) :global(.button) {
  border: none;
  pointer-events: auto;
}
:global(.blog) :global(.button:focus) {
  color: #ffffff;
}</style>

<div class="blog">
  <div class="home-button__outer" class:sticky="{articleWidth >= 1300}">
    <div class="home-button">
      {#if backToProjects}
        <a href="/#projects" class="button is-rounded">Back to Home</a>
      {:else}
        <a href="/" class="button is-rounded">Visit Main Site</a>
      {/if}
    </div>
  </div>

  <div class="blog-article__outer" bind:clientWidth="{articleWidth}">
    <div class="blog-article content is-medium">
      <slot />
    </div>
  </div>
</div>
