<script>
  import Discover from "./Discover.svelte";
  import Ideate from "./Ideate.svelte";
  import Make from "./Make.svelte";
  import Watch from "./Watch.svelte";
  import Glide, {
    Breakpoints,
    Controls,
    Swipe,
  } from "@glidejs/glide/dist/glide.modular.esm";
  import { onMount } from "svelte";

  let glideDOM;

  onMount(() => {
    const glide = new Glide(glideDOM, {
      type: "carousel",
      gap: 0,
    }).mount({ Breakpoints, Controls, Swipe });

    return () => glide.destroy();
  });
</script>

<style lang="scss">.glide {
  margin-bottom: -1px;
}

button.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 4px;
  border-radius: 50%;
  width: 84px;
  height: 84px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
button.arrow svg {
  transform: translate(-1px, 13px);
}
button.arrow.arrow-left {
  transform: rotate(90deg);
}
button.arrow.arrow-right {
  transform: rotate(-90deg);
}
button.arrow:hover {
  background-color: #fffa;
}
button.arrow:hover.arrow-left {
  transform: rotate(90deg) translateY(0.25rem);
}
button.arrow:hover.arrow-right {
  transform: rotate(-90deg) translateY(0.25rem);
}</style>

<div class="glide" bind:this="{glideDOM}">
  <div data-glide-el="track" class="glide__track">
    <ul class="glide__slides">
      <li class="glide__slide"><Discover /></li>
      <li class="glide__slide"><Ideate /></li>
      <li class="glide__slide"><Make /></li>
      <li class="glide__slide"><Watch /></li>
    </ul>
  </div>

  <div class="glide__arrows" data-glide-el="controls">
    <button
      class="glide__arrow glide__arrow--left arrow arrow-left"
      data-glide-dir="<">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 19 19"
        preserveAspectRatio="xMidYMid"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.06 0.377441L9.9375 7.25494L16.815 0.377441L18.9375
              2.49994L9.9375 11.4999L0.9375 2.49994L3.06 0.377441Z"
          fill="#000"></path>
      </svg></button>
    <button
      class="glide__arrow glide__arrow--right arrow arrow-right"
      data-glide-dir=">">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 19 19"
        preserveAspectRatio="xMidYMid"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.06 0.377441L9.9375 7.25494L16.815 0.377441L18.9375
              2.49994L9.9375 11.4999L0.9375 2.49994L3.06 0.377441Z"
          fill="#000"></path>
      </svg></button>
  </div>
</div>
