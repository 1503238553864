<style lang="scss">p {
  font-size: 0.9rem;
}

a {
  color: #4a4a4a;
  padding: 0;
}
a:hover {
  color: #000;
}</style>

<p>
  <a href="https://www.linkedin.com/company/dna-pathways/" target="_blank">
    <svg
      width="60"
      height="16"
      viewBox="0 0 60 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.101273
                      2.44531H2.40723V11.1233H6.47303V12.6704H0.101273V2.44531Z"
        fill="#4a4a4a"></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.49049 4.73589C9.26425 4.73589 9.73457 4.22001
                      9.73457 3.58279C9.71939 2.9305 9.26425 2.42969 8.52086
                      2.42969C7.77747 2.42969 7.29196 2.93038 7.29196
                      3.58279C7.29196 4.22014 7.76229 4.73589 8.49049
                      4.73589ZM7.3527 5.77757V12.6699H9.65878V5.77757H7.3527Z"
        fill="#4a4a4a"></path>
      <path
        d="M11.0996 8.01296C11.0996 7.08749 11.0691 6.44537
                      11.0389 5.7779H13.1426L13.1477 6.67802H13.1933C13.4967
                      6.19251 14.24 5.47949 15.484 5.47949C17.0011 5.47949
                      18.139 6.49594 18.139
                      8.68055V12.6702H15.833V8.95361C15.833 7.99778 15.4992
                      7.34549 14.6647 7.34549C14.0276 7.34549 13.6483 7.78545
                      13.4814 8.21035C13.4206 8.36206 13.4056 8.57451 13.4056
                      8.78684V12.6703H11.0996V8.01296Z"
        fill="#4a4a4a"></path>
      <path
        d="M21.8859 8.77113H21.9162C22.0831 8.45264 22.2651
                      8.14921 22.4472 7.87615L23.9492 5.7775H26.7253L24.0704
                      8.64991L27.1046 12.6698H24.2677L22.4624 10.0151L21.8859
                      10.7432V12.6697H19.58V1.89844H21.8859V8.77113Z"
        fill="#4a4a4a"></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.0491 11.5019C29.972 11.5019 29.0161 11.0469 28.9555
                      10.0911V10.091H33.7346C33.7649 9.92394 33.8104 9.57509
                      33.8104 9.18068C33.8104 7.34492 32.9001 5.74219 30.503
                      5.74219C27.924 5.74219 26.7558 7.55737 26.7558
                      9.43855C26.7558 11.7598 28.1972 12.8215 30.7155
                      12.8215C31.7169 12.8215 32.6422 12.6697 33.4007
                      12.3511L33.0972 11.1832C32.4752 11.3957 31.838 11.5019
                      31.0491 11.5019ZM30.3664 7.01137C31.4282 7.01137 31.6709
                      7.95202 31.6709 8.51333H28.9403C29.0009 7.9066 29.3955
                      7.01137 30.3664 7.01137Z"
        fill="#4a4a4a"></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.1035
                      10.8495V1.89844H39.7975V6.29827H39.7671C39.4333 5.79758
                      38.7356 5.46391 37.8102 5.46391C36.0351 5.46391 34.4726
                      6.92031 34.4876 9.42342C34.4876 11.7293 35.8986 12.8366
                      37.6585 12.8366C38.6143 12.8366 39.5245 12.4117 39.9796
                      11.5926H40.0251L40.116 12.6697H42.1642L42.1622
                      12.6361C42.1326 12.1471 42.1035 11.6651 42.1035
                      10.8495ZM39.7673 8.36154C39.7976 8.49795 39.7976 8.66485
                      39.7976 8.78619V9.75721C39.7976 9.93929 39.7825 10.1214
                      39.7521 10.2883C39.6156 10.9406 39.0694 11.3957 38.4018
                      11.3957C37.4461 11.3957 36.8242 10.6068 36.8242
                      9.34774C36.8242 8.17958 37.3551 7.23893 38.417
                      7.23893C39.13 7.23893 39.6307 7.75456 39.7673 8.36154Z"
        fill="#4a4a4a"></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.3524 0.0537109H59.2717C59.6113 0.0537109 59.8891
                      0.331548 59.8891 0.670976V14.5905C59.8891 14.93 59.6113
                      15.2079 59.2717 15.2079H45.3524C45.0127 15.2079 44.735
                      14.93 44.735 14.5905V0.671099C44.735 0.33167 45.0127
                      0.0537109 45.3524 0.0537109ZM47.5131
                      12.4178H49.6164V5.63714H47.5131V12.4178ZM48.5819
                      4.8864C47.9176 4.8864 47.4886 4.41596 47.4886
                      3.83481C47.4886 3.23983 47.9314 2.7831 48.6095
                      2.7831C49.2875 2.7831 49.7029 3.23983 49.7165
                      3.83481C49.7166 4.41596 49.2877 4.8864 48.5819
                      4.8864ZM55.3131 12.4185H57.4167V8.54604C57.4167 6.5533
                      56.3787 5.62624 54.995 5.62624C53.86 5.62624 53.3673
                      6.27657 53.0907 6.71947H53.0182L53.0203
                      5.63714H50.9405C50.9682 6.24608 50.9958 7.09306 50.9958
                      7.9371V12.4173H53.0993V8.64277C53.0993 8.44906 53.1131
                      8.25522 53.1684 8.11685C53.3206 7.72943 53.6664 7.32816
                      54.2476 7.32816C55.0087 7.32816 55.3131 7.92326 55.3131
                      8.79498V12.4185Z"
        fill="#4a4a4a"></path>
    </svg>
  </a>
</p>
