<script>
  import { onMount } from "svelte";

  import SocialPolka from "./SocialPolka.svelte";

  let w,
    isMobile = false,
    isTablet = false;
  let mounted;

  $: if (w <= 1216) {
    isMobile = true;
    if (w >= 768) {
      isTablet = true;
    } else {
      isTablet = false;
    }
  } else {
    isMobile = false;
    isTablet = false;
  }

  onMount(() => {
    requestAnimationFrame(() => {
      mounted = true;
    });
  });
</script>

<style lang="scss">p {
  font-family: "BrownStd-Light";
  color: #ffffff;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1rem;
  text-align: left;
}

.text-align {
  align-self: flex-start;
  margin-left: 10%;
  margin-right: 10%;
}

.service-tagline {
  align-self: flex-end;
  text-align: right;
  margin-left: 10%;
  margin-right: 10%;
}

.service-content.is-mobile {
  margin-left: 10%;
}

.image-container {
  position: relative;
  width: 100vw;
  margin-bottom: 4rem;
}

.image-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(20, 20, 20, 0), #141414);
}

.service-content-mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  bottom: 16rem;
  margin-bottom: -16rem;
}</style>

{#if isMobile}
  <div class="image-container">
    {#if mounted}
      <SocialPolka fillStyle="fill: #712bdf;" />
    {/if}
    <div class="image-overlay" class:is-tablet="{isTablet}"></div>
    <img
      width="100%"
      src="/assets/img/social.png"
      alt="Woman using a laptop computer" />
  </div>
  <br />
{/if}
<div class:service-content-mobile="{isMobile}" class:is-tablet="{isTablet}">
  <h1 class="title text-align">Social & Entertainment</h1>
  <div>
    <p>
      We are constantly transforming the way we connect and seek out
      entertainment. A lot is changing in how we stay up to date, find content
      we love, and entertain ourselves and others. We help you become positioned
      at the forefront of change and lead the way in emerging social norms,
      artistic expression, and innovation. We also love helping creatives do
      what they do best, by creating tools, genres and platforms that enhance
      their craft.
    </p>
    <p>
      Want to pioneer a new way for connection, collaboration, and creativity?
      We’re the crew for you.
    </p>
  </div>
  <h1 class="title service-tagline" class:is-mobile="{isMobile}">
    Redesign
    <br />
    <span class="purple">how we live, connect, and play.</span>
  </h1>
</div>

<svelte:window bind:innerWidth="{w}" />
