<script>
  import Make from "./svg/make/Make.svg";
  import MakeMobile from "./svg/make/MakeMobile.svg";

  let w,
    isTablet = false,
    isMobile = false,
    isNarrow = false;

  $: if (w <= 1024) {
    isTablet = true;
    if (w <= 600) {
      isMobile = true;
      if (w <= 350) {
        isNarrow = true;
      } else {
        isNarrow = false;
      }
    } else {
      isMobile = false;
    }
  } else {
    isTablet = false;
    isMobile = false;
    isNarrow = false;
  }
</script>

<style lang="scss">.slide-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-flex-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 77%;
}

.mobile-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.section-title {
  color: #141414;
  font-size: 0.7rem;
}

.title {
  color: #141414;
}

h1.is-huge {
  max-width: 40rem;
  font-size: 5rem;
  letter-spacing: -0.2rem;
}

h1.is-huge-mobile {
  font-size: 3rem;
  letter-spacing: -0.1rem;
  text-align: center;
}

.svg-expand {
  min-height: 100%;
  min-width: 100%;
}

.slide-container {
  background-color: #fe5832;
}

.title {
  color: #ffffff;
  padding: 0 1rem;
}

.svg-expand {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6vw 12vw 0;
}

.loop-group {
  position: relative;
  padding-right: 1rem;
  width: 70%;
  height: 100%;
  margin-bottom: -20vw;
}

.make-label {
  position: absolute;
  padding-left: 12vw;
  padding-right: 6vw;
  color: #ffffff;
  width: 80vw;
}

.make-label-1 {
  top: 12%;
}

.make-label-2 {
  top: 36%;
}

.make-label-3 {
  top: 60%;
}

.make-label-4 {
  top: 84%;
}

.label-heading {
  font-weight: bold;
}</style>

<div class="slide-container">
  <div class="svg-flex-container" class:mobile-container="{isTablet}">
    {#if isTablet}
      <div class="section">
        <h6 class="title is-6 section-title">HOW WE WORK</h6>
      </div>
      <h1 class="title is-huge is-huge-mobile">Make & Take to Market</h1>

      <div class="svg-expand">
        <div class="loop-group">
          {@html MakeMobile}
          <div class="make-label make-label-1">
            <div class="label-heading">Prototype</div>
            <p>
              Make a low fidelity version. Test it with potential users. Adjust
              anything that reduces quality.
            </p>
          </div>
          <div class="make-label make-label-2">
            <div class="label-heading">Pilot</div>
            <p>
              Run a pilot study (or a few). Iterate and refine design based on
              real-life feedback.
            </p>
          </div>
          <div class="make-label make-label-3">
            <div class="label-heading">Launch</div>
            <p>
              Go to market. Watch as users enjoy what you create. Discover
              insights for (re)design.
            </p>
          </div>
          <div class="make-label make-label-4">
            <div class="label-heading">Grow</div>
            <p>
              Evolve with your users. Add ongoing value by responding to their
              needs and pre-empting the changes.
            </p>
          </div>
        </div>
      </div>
    {:else}
      {@html Make}
    {/if}
  </div>
</div>

<svelte:window bind:innerWidth="{w}" />
