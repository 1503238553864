<script>
  import HeaderMessage from "./HeaderMessage.svelte";

  const TAGLINE = "Design for life.";

  let w,
    messageHeight,
    isMobile = false,
    isTablet = false;

  $: if (w <= 1024) {
    isMobile = true;
    if (w > 768) {
      isTablet = true;
    } else {
      isTablet = false;
    }
  } else {
    isMobile = false;
    isTablet = false;
  }
</script>

<style lang="scss">.tagline-container {
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 5%;
}

.tagline {
  font-family: "BrownStd-Bold";
  font-size: 5vw;
  color: #ffffff;
  letter-spacing: -0.35vw;
}

.tagline-mobile {
  font-size: 11vw;
  letter-spacing: -0.15vw;
  margin-top: 30%;
}

.message-container {
  position: absolute;
  bottom: 5%;
  width: 100%;
}

.tagline.is-tablet {
  font-size: 9vw;
  letter-spacing: -0.1vw;
}</style>

<div class="tagline-container">
  {#if isMobile}
    <h1 class="tagline tagline-mobile" class:is-tablet="{isTablet}">
      {TAGLINE}
    </h1>
    <HeaderMessage />
  {:else}
    <h1 class="tagline">{TAGLINE}</h1>
    <div class="message-container">
      <HeaderMessage />
    </div>
  {/if}
</div>

<svelte:window bind:innerWidth="{w}" />
