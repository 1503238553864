<script>
  import { onMount } from "svelte";

  export let tabIndex;

  let w,
    isNavBottom = false;

  $: if (w <= 1024) {
    isNavBottom = true;
  } else {
    isNavBottom = false;
  }
</script>

<style lang="scss">.sticky-nav {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  min-width: 100%;
  overflow-x: scroll;
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  font-size: 0.7rem;
}

.sticky-nav-small {
  bottom: 52px;
}

.sticky-nav-item > a {
  display: block;
  flex: 0 0 auto;
  list-style-type: none;
  color: gray;
  border: 0 solid #ffffff;
  border-top-width: 5px;
  padding: 1rem;
  text-align: center;
}

.sticky-nav-item.is-active-green > a {
  border-color: #00ff7d;
  color: #141414;
}

.sticky-nav-item.is-active-orange > a {
  border-color: #fe5832;
  color: #141414;
}

.sticky-nav-item.is-active-purple > a {
  border-color: #5f1ec4;
  color: #141414;
}

.sticky-nav-item.is-active-cyan > a {
  border-color: #00edfd;
  color: #141414;
}</style>

<ul class="sticky-nav" class:sticky-nav-small="{isNavBottom}">
  <li
    class="sticky-nav-item"
    class:is-active-green="{tabIndex === 0}"
    on:click="{() => (tabIndex = 0)}">
    <a>Data Models & Academic</a>
  </li>
  <li
    class="sticky-nav-item"
    class:is-active-orange="{tabIndex === 1}"
    on:click="{() => (tabIndex = 1)}">
    <a>Commercial & Retail</a>
  </li>
  <li
    class="sticky-nav-item"
    class:is-active-purple="{tabIndex === 2}"
    on:click="{() => (tabIndex = 2)}">
    <a>Social & Entertainment</a>
  </li>
  <li
    class="sticky-nav-item"
    class:is-active-cyan="{tabIndex === 3}"
    on:click="{() => (tabIndex = 3)}">
    <a>Systems R&D</a>
  </li>
</ul>

<svelte:window bind:innerWidth="{w}" />
