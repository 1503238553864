<style lang="scss">p {
  color: #ffffff;
  max-width: 28rem;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 3px #000;
}

.button {
  border: none;
}

.calendly__outer {
  margin-top: 1.5rem;
}</style>

<p>
  <strong>We care about life.</strong>
  DNA Pathways specialises in digital and R&D for purpose-driven organisations. We help you evolve around emerging customer and community needs, and consciously pivot to realise your vision and purpose. Whether you are an established organisation or just starting out - our unique R&D methodology can be applied to any part of the project and evolutionary step of your journey.
</p>
<p>
  DNA Pathways pioneers a unique flavour of Design Thinking, Agile Delivery and Continuous Optimisation, adapted to distributed organisations and workflows. We tailor each engagement to provide you with the right team of designers, scientists and tech experts for your unique challenge. Our aim is to create bespoke processes, tools and digital ecosystems that enable your organisation, and everything it touches, to thrive!
</p>
<p>
  DNA Pathways is based in Northern NSW, Australia. We only take on projects that have a strong moral compass and aim to do good. If that’s your mission and you share our vision for a healthy, happy, balanced world - reach out. We’d love to collaborate with you!

</p>

<div class="calendly__outer">
  <a
    href="https://calendly.com/dennis-dna-pathways/dna-pathways"
    class="calendly"
    target="_blank">
    <button class="button is-white is-rounded">Schedule Meeting</button>
  </a>
</div>
