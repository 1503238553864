<style lang="scss">h1,
h2,
p,
li {
  color: #fff;
}

p,
li {
  font-size: 1rem;
  font-family: "BrownStd-Light";
}

a {
  color: #fe5832;
}
a:hover {
  color: #fff;
}</style>

<nav class="navbar is-white">
  <div class="navbar-brand">
    <a href="/" class="navbar-item">Back</a>
  </div>
</nav>

<article class="section">
  <div class="container content">
    <h1 class="title">Privacy Policy</h1>
    <h2>COPYRIGHT</h2>
    <p>
      The material on this website is owned by DNA Pathways Pty Ltd in each
      country, unless we indicate otherwise. You may make a copy of the material
      for your personal use. You are not otherwise authorised to copy, reproduce
      or communicate the material without our prior written permission.
    </p>

    <h2>DISCLAIMER</h2>
    <p>
      The material on this website does not contain consulting advice or
      recommendations. It is intended to provide general information about DNA
      Pathways and our services. While we have used all reasonable endeavours to
      ensure that the information on this website is as accurate as possible, we
      cannot guarantee that the material is accurate, complete, current or fit
      for any purpose.
    </p>

    <h2>PRIVACY POLICY – DNA PATHWAYS PTY LTD</h2>
    <p>
      This privacy policy applies to the collection, storage, use and disclosure
      of personal information by or on behalf of DNA Pathways Pty Ltd (ACN 635
      673 086). We are committed to protecting personal information collected
      either through our research work, or design consultancy services, and
      ensuring its privacy, accuracy and security. We handle your personal
      information in a responsible manner in accordance with the
      <a target="_blank" href="https://www.oaic.gov.au/privacy/the-privacy-act/"
        >Privacy Act 1988</a>
      (Act) and the
      <a
        target="_blank"
        href="https://www.oaic.gov.au/privacy/australian-privacy-principles/"
        >Australian Privacy Principles</a>
      (APPs). By using any of our services, visiting our website you agree to information
      collected, stored, used and disclosed as set out in this Privacy Policy.
    </p>

    <h2>WHOSE PERSONAL INFORMATION DO WE COLLECT?</h2>
    <p>
      We may collect your personal information from a range of sources. For
      example, we may collect your personal information when you request or
      acquire a service from us, provide a service or product to us, apply for
      employment with us or communicate with us via our website, by email, or in
      writing. Wherever reasonable and practicable, we collect personal
      information from the individual to whom the information relates. If you
      provide personal information about someone other than yourself, you agree
      that you have that person’s consent to provide the information for the
      purpose for which you provide it to us. You also agree that you have told
      the person about this Privacy Policy and where to find it.
    </p>

    <h2>WHAT TYPES OF PERSONAL INFORMATION DO WE COLLECT AND HOLD?</h2>
    <p>
      We will only collect your information if you have consented to us doing
      so, for example when we conduct research we always ask participants to
      complete consent forms. The personal information we collect includes:
    </p>
    <ul>
      <li>
        Client details: names, e-mail addresses, phone numbers, payment details,
        occupation and other information to assist us in conducting our
        business, providing and marketing our services;
      </li>
      <li>
        Staff: information about potential staff, staff and partners, as
        required in the normal course of human resource management and the
        operation of a business.
      </li>
      <li>
        Research participants: names, email addresses, phone numbers, payment
        details, occupation and other information to assist us in conducting our
        services.
      </li>
    </ul>

    <h2>HOW DO WE COLLECT PERSONAL INFORMATION?</h2>
    <p>
      We only collect personal information by lawful and fair means. We usually
      collect personal information from:
    </p>
    <ul>
      <li>face-to-face meetings, interviews and telephone calls</li>
      <li>business cards</li>
      <li>
        electronic communications – for example, emails and attachments, and
        forms filled out by people, including as part of attendance at seminars
        or through acquiring a service from us
      </li>
      <li>
        subscriptions to marketing materials such as newsletters and electronic
        briefs
      </li>
      <li>
        third parties – for example, from research recruitment agencies and your
        representatives or agents our website and social media pages, including
        if you use them to contact us.
      </li>
    </ul>

    <h2>WHY DO WE COLLECT PERSONAL INFORMATION?</h2>
    <p>
      We collect the personal information: necessary for us to provide you with
      the services you have requested from us; for marketing purposes and to
      provide you with information about services that may be of interest to
      you; to improve the services we provide; and to enable us to conduct our
      business, including conducting research and your participation in that
      research. If you do not provide your personal information, we may not be
      able to supply the requested service, employ you or otherwise deal with
      you.
    </p>

    <h2>HOW WE DEAL WITH UNSOLICITED PERSONAL INFORMATION?</h2>
    <p>
      If we receive personal information about you that we have not requested,
      and we determine that we could not have lawfully collected that
      information under the APPs had we asked for it, we will destroy or
      de-identify the information if it is lawful and reasonable to do so.
    </p>

    <h2>DO YOU HAVE TO DISCLOSE YOUR IDENTITY WHEN DEALING WITH US?</h2>
    <p>
      Where lawful and practicable, we will give you the option of interacting
      with us anonymously or using a pseudonym.
    </p>

    <h2>USE OF PERSONAL INFORMATION:</h2>
    <p>
      We only use your personal information for the purpose for which it was
      provided to us, for related purposes or as required or permitted by law.
      Such purposes include:
    </p>
    <ul>
      <li>
        in the ordinary course of conducting our business. For example,
        supplying services such as consulting advice, acquiring products and
        services, responding to your enquiries and feedback, and providing
        information about our events, news, publications and services that may
        be of interest to you;
      </li>
      <li>
        conducting research projects, so that we know who is involved in the
        research projects
      </li>
      <li>
        market research and service development, so that we are able to better
        understand our clients’ needs and tailor our future services
        accordingly;
      </li>
      <li>
        performing general administration, reporting and management functions.
        For example, invoicing and account management, payment processing,
        credit and risk management, training, quality assurance and managing
        suppliers;
      </li>
      <li>
        employment-related purposes, such as recruiting and providing services
        to staff;
      </li>
      <li>
        other purposes related to or in connection with our business, including
        meeting our legal and contractual obligations to third parties and for
        internal governance purposes.
      </li>
    </ul>

    <h2>DISCLOSURE OF PERSONAL INFORMATION</h2>
    <p>
      We may disclose, and you consent to us disclosing, your personal
      information to third parties:
    </p>
    <ul>
      <li>
        engaged by us to provide products or services, or to undertake functions
        or activities, on our behalf. For example, managing databases,
        marketing, research and advertising;
      </li>
      <li>that are authorised by you to receive information we hold,</li>
      <li>
        that are our business partners, joint venturers, partners or agents;
      </li>
      <li>
        such as our external advisers, and government agencies. For example,
        where disclosure is reasonably required to obtain advice, prepare legal
        proceedings or investigate suspected unlawful activity or serious
        misconduct; or
      </li>
      <li>as required or permitted by law.</li>
    </ul>

    <h2>MARKETING USE AND DISCLOSURE</h2>
    <p>
      We may use and disclose your personal information (other than sensitive
      information) to provide you with information about our services that we
      consider may be of interest to you. You may opt out at any time if you do
      not, or no longer, wish to receive marketing and promotional material. You
      may do this by: contacting us via e-mail or in writing at the address
      below and requesting that we no longer send you marketing or promotional
      material; or where applicable, clicking the “Unsubscribe” button.
    </p>

    <h2>USE OR DISCLOSURE OF SENSITIVE INFORMATION</h2>
    <p>
      We will only use or disclose your sensitive information for the purpose
      for which it was initially collected or for a directly related purpose, as
      required or permitted by law, or where you consent to the use or
      disclosure.
    </p>

    <h2>DISCLOSURE OF PERSONAL INFORMATION OVERSEAS</h2>
    <p>
      We do not disclose personal information to third parties outside
      Australia, unless required or permitted by law.
    </p>

    <h2>HOW IS MY PERSONAL INFORMATION KEPT SECURE?</h2>
    <p>
      We take reasonable steps to protect your personal information from misuse,
      interference, loss and unauthorised access, modification and disclosure.
      Such steps include: physical security over paper-based and electronic data
      storage and premises; computer and network security measures, including
      use of firewalls, password access and secure servers; restricting access
      to your personal information to employees and those acting on our behalf
      who are authorised and on a ‘need to know’ basis; retaining your personal
      information for no longer than it is reasonably required, unless we are
      required by law to retain it for longer; and entering into confidentiality
      agreements with staff and third parties. Where we no longer require your
      personal information, including where we are no longer required by law to
      keep records relating to you, we will ensure that it is de-identified or
      destroyed.
    </p>

    <h2>DATA QUALITY</h2>
    <p>
      We take reasonable steps to ensure that your personal information is
      accurate, complete and up-to-date. However, we rely on you to advise us of
      any changes or corrections to the information we hold about you. If you
      consider that the information we hold about you is not accurate, complete
      or up-to-date, or if your information has changed, please let us know as
      soon as possible.
    </p>

    <h2>ACCESS</h2>
    <p>
      You may request access to the personal information we hold about you by
      contacting us. We will respond to your request within a reasonable time.
      We will provide you with access to the information we hold about you
      unless otherwise permitted or required by law. If we deny you access to
      the information, we will notify you of the basis for the denial unless an
      exception applies. Where reasonable and practicable, we will provide
      access to the information we hold about you in the manner you request. No
      fee applies for requesting access to information we hold about you.
      However, we reserve the right to charge a reasonable fee where we do
      provide access.
    </p>

    <h2>CORRECTION</h2>
    <p>
      If you believe that personal information we hold about you is incorrect,
      incomplete or not current, you may request that we update or correct your
      information by contacting us. We will deal with your request within a
      reasonable time. If we do not agree with the corrections you have
      requested (for example, because we consider that the information is
      already accurate, up‑to‑date, complete, relevant and not misleading), we
      are not required to make the corrections. However, where we refuse to do
      so, we will give you a written notice setting out the reasons.
    </p>

    <h2>IDENTIFIERS</h2>
    <p>
      We do not adopt, use or disclose government related identifiers except as
      required or permitted by law.
    </p>

    <h2>COMPLAINTS</h2>
    <p>
      If you have a complaint in relation to the collection, storage, use or
      disclosure of your personal information, please contact our Company
      Secretary using the details below. You will need to provide us with
      details of your complaint, as well as any supporting evidence and
      information. We will review all complaints received and our Company
      Secretary will respond to you. If you are not satisfied with our response,
      you may discuss your concerns with or complain to the Australian Privacy
      Commissioner via
      <a target="_blank" href="http://www.oaic.gov.au">www.oaic.gov.au</a>.
    </p>

    <h2>CHANGES TO THIS PRIVACY POLICY</h2>
    <p>
      We reserve the right to revise this Privacy Policy or any part of it from
      time to time. Please review this Policy periodically for changes. Any
      revised policy will be placed on our website at
      <a href="https://dnapathways.io/privacy-policy"
        >dnapathways.io/privacy-policy</a
      >.
    </p>
    <p>
      Your continued use of our website, services, requesting our assistance, or
      the provision of further personal information to us after this Privacy
      Policy has been revised, constitutes your acceptance of the revised
      Privacy Policy.
    </p>

    <h2>HOW TO CONTACT US</h2>
    <p>
      If you have any questions about this Privacy Policy, please contact our
      Company Secretary:
    </p>
    <ol>
      <li>
        by email to
        <a href="mailto:info@dnapathways.com.au">info@dnapathways.com.au</a>
      </li>
      <li>
        by writing to: Company Secretary, PO Box 367, Mullumbimby, 2482 NSW,
        AUSTRALIA
      </li>
    </ol>
    <p>Effective date: 7 July 2021</p>
  </div>
</article>
