<style lang="scss">#cyanDots {
  fill: #00edfd;
}

.logo-container {
  position: absolute;
  height: 100%;
  width: 100%;
}</style>

<div class="logo-container">
  <svg
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <pattern
        id="logoWhyPattern"
        x="0"
        y="0"
        width="20"
        height="34.6410161514"
        patternUnits="userSpaceOnUse"
        patternTransform="scale(0.75)">
        <g id="cyanDots">
          <circle cx="5" cy="8.6602540378" r="1"></circle>
          <circle cx="15" cy="25.9807621135" r="1"></circle>
        </g>
      </pattern>
      <mask id="logoWhyMask">
        <svg
          width="100%"
          height="100%"
          viewBox="-50 0 100 100"
          preserveAspectRatio="xMidYMid meet">
          <g transform="scale(3)">
            <path
              d="M17.2 0.440002L0.390015 17.25V25.65H8.79001V34.05H17.2L34
              17.25V8.84V0.440002H17.2Z"
              fill="white"></path>
          </g>
        </svg>
      </mask>
    </defs>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      fill="url(#logoWhyPattern)"
      mask="url(#logoWhyMask)"></rect>
  </svg>
</div>
