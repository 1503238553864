<script>
  import { scrollTo, scrollToTop } from "svelte-scrollto";
  import CTA from "./CTA.svelte";

  const offsetScroll = -55;

  let w,
    scrollY,
    isDesktop = true,
    collapseNav = false;

  $: if (w <= 1024) {
    isDesktop = false;
  } else {
    isDesktop = true;
    collapseNav = false;
  }

  $: if (scrollY === 0) {
    collapseNav = false;
  } else {
    if (isDesktop) {
      collapseNav = true;
    } else {
      collapseNav = false;
    }
  }
</script>

<style lang="scss">.navbar-item {
  color: white;
  cursor: pointer;
  background-color: #141414;
  font-family: "BrownStd-Light";
}

.navbar-item.is-mobile {
  font-size: 0.8rem;
  border: 0;
  padding: 0;
}

.navbar-item.is-mobile:focus {
  outline: none;
}

.item-padding {
  padding-left: 3rem;
  padding-right: 3rem;
}

.navbar-item.is-tab:hover {
  border-bottom-color: #fe5832;
}

.navbar-end > .button {
  color: #ffffff;
}

.navbar-end > .button:hover {
  color: #141414;
}

nav,
.navbar-brand img {
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

nav.is-scrolling {
  padding: 0.5rem;
  padding-top: 0px;
  padding-bottom: 3px;
}

.navbar-brand.is-scrolling img {
  max-height: 2rem;
}

.cta-wrapper {
  width: 139px;
  height: 41px;
}

.cta-wrapper-mobile {
  position: relative;
  margin-left: auto;
  margin-top: 10px;
  margin-right: 10px;
  width: 139px;
  height: 41px;
}

.navbar-start {
  padding-top: 3px;
}

.navbar-menu {
  box-shadow: none;
}

.bottom-nav-container {
  display: flex;
  min-width: 100%;
}

.bottom-nav-container > * {
  flex: 1;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  font-family: "BrownStd-Regular";
  font-size: 0.8rem;
}

.item-get-started {
  background-color: #fe5832;
}</style>

<nav
  class="navbar is-transparent is-spaced"
  class:is-fixed-top="{isDesktop}"
  class:is-fixed-bottom="{!isDesktop}"
  class:is-scrolling="{collapseNav}">
  <div class="navbar-brand" class:is-scrolling="{collapseNav}">
    {#if isDesktop}
      <a on:click="{() => scrollToTop()}" class="navbar-item">
        <img
          src="/assets/svg/logo-navbar.svg"
          alt="DNA Pathways logo is an arrow pointing diagonally up and to the
          right" />
      </a>
    {:else}
      <div class="bottom-nav-container">
        <button
          class="navbar-item is-mobile"
          on:click="{() => scrollTo({ element: '#why' })}">
          <span>Why?</span>
        </button>
        <button
          class="navbar-item is-mobile"
          on:click="{() => scrollTo({ element: '#how' })}">
          <span>How?</span>
        </button>
        <button
          class="navbar-item is-mobile"
          on:click="{() => scrollTo({ element: '#what' })}">
          <span>What?</span>
        </button>
        <!-- <button
          class="navbar-item is-mobile"
          on:click="{() => scrollTo({ element: '#resources' })}">
          <span>Resources</span>
        </button> -->
        <button
          class="navbar-item is-mobile item-get-started"
          on:click="{() => scrollTo({ element: '#getStarted' })}">
          <span>Get Started</span>
        </button>
      </div>
    {/if}
  </div>
  <div class="navbar-menu">
    <div class="navbar-start">
      <div
        class="navbar-item is-tab item-padding"
        on:click="{() => scrollTo({ element: '#why', offset: offsetScroll })}">
        <a>Why?</a>
      </div>
      <div
        class="navbar-item is-tab item-padding"
        on:click="{() => scrollTo({ element: '#how', offset: offsetScroll })}">
        <a>How?</a>
      </div>
      <div
        class="navbar-item is-tab item-padding"
        on:click="{() => scrollTo({ element: '#what' })}">
        <a>What?</a>
      </div>
      <!-- HIDE RESOURCERS
        <div
        class="navbar-item is-tab item-padding"
        on:click="{() => scrollTo({ element: '#resources' })}">
        <a>Resources</a>
      </div>-->
    </div>
    {#if isDesktop}
      <div class="navbar-end">
        <a
          class="cta-wrapper"
          on:click="{() => scrollTo({ element: '#getStarted' })}">
          <CTA />
        </a>
      </div>
    {/if}
  </div>
</nav>

<svelte:window bind:innerWidth="{w}" bind:scrollY />
