<script>
  import FooterLogo from "./FooterLogo.svelte";

  import FooterSocials from "./FooterSocials.svelte";

  import FooterLegal from "./FooterLegal.svelte";

  import FooterTagline from "./FooterTagline.svelte";

  import FooterContact from "./FooterContact.svelte";

  import FooterLocations from "./FooterLocations.svelte";

  import FooterTaglineMobile from "./FooterTaglineMobile.svelte";

  let w,
    isNarrowMobile = false,
    columnsTooSquished = false;

  $: if (w >= 768) {
    isNarrowMobile = false;
    columnsTooSquished = false;
  } else {
    isNarrowMobile = true;

    if (w < 380) {
      columnsTooSquished = true;
    } else {
      columnsTooSquished = false;
    }
  }
</script>

<style lang="scss">.tagline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  padding-bottom: 6rem;
}</style>

<hr />

{#if isNarrowMobile}
  <div class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <FooterLogo />
        </div>
        <div class="column tagline">
          <FooterTaglineMobile />
        </div>
        <div class="column">
          <br />
          <div class="columns" class:is-mobile="{!columnsTooSquished}">
            <div class="column">
              <FooterLocations />
            </div>
            <div class="column">
              <FooterContact />
            </div>
          </div>

          <div class="columns">
            <div class="column is-one-quarter"></div>
            <div class="column">
              <hr />
              <br />
              <div class="columns">
                <div class="column is-two-thirds">
                  <FooterLegal />
                </div>
                <div class="column">
                  <FooterSocials />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{:else}
  <div class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <FooterLogo />
        </div>

        <div class="column">
          <FooterTagline />
        </div>

        <div class="column">
          <FooterLocations />
        </div>

        <div class="column">
          <FooterContact />
        </div>
      </div>

      <div class="columns">
        <div class="column is-one-quarter"></div>
        <div class="column">
          <hr />
          <br />
          <div class="columns">
            <div class="column is-two-thirds">
              <FooterLegal />
            </div>
            <div class="column">
              <FooterSocials />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<svelte:window bind:innerWidth="{w}" />
