<style lang="scss">.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}</style>

<div class="logo">
  <img
    src="/assets/svg/logo-footer.svg"
    alt="logo is an arrow pointing diagonally up and to the right" />
</div>
