<script>
  import { onMount } from "svelte";

  import SystemsPolka from "./SystemsPolka.svelte";

  let w,
    isMobile = false,
    isTablet = false;
  let mounted;

  $: if (w <= 1216) {
    isMobile = true;
    if (w >= 768) {
      isTablet = true;
    } else {
      isTablet = false;
    }
  } else {
    isMobile = false;
    isTablet = false;
  }

  onMount(() => {
    setInterval(() => {
      mounted = true;
    });
  });
</script>

<style lang="scss">p {
  font-family: "BrownStd-Light";
  color: #ffffff;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1rem;
  text-align: left;
}

.text-align {
  align-self: flex-start;
  margin-left: 10%;
  margin-right: 10%;
}

.service-tagline {
  align-self: flex-end;
  text-align: right;
  margin-left: 10%;
  margin-right: 10%;
}

.service-content.is-mobile {
  margin-left: 10%;
}

.image-container {
  position: relative;
  width: 100vw;
  margin-bottom: 4rem;
}

.image-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(20, 20, 20, 0), #141414);
}

.service-content-mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  bottom: 16rem;
  margin-bottom: -16rem;
}</style>

{#if isMobile}
  <div class="image-container">
    {#if mounted}
      <SystemsPolka fillStyle="fill: #00edfd;" />
    {/if}
    <div class="image-overlay" class:is-tablet="{isTablet}"></div>
    <img
      width="100%"
      src="/assets/img/systems.png"
      alt="Man using a laptop computer showing architectural plans" />
  </div>
  <br />
{/if}
<div class:service-content-mobile="{isMobile}" class:is-tablet="{isTablet}">
  <h1 class="title text-align">Systems R&D</h1>
  <div>
    <p>
      Have an idea or design challenge, but not sure how to go about it? We help
      you scope, design, and create whatever you need. We’re experts in roadmaps
      for product/service evolution, so that you can evolve your idea through
      iteration and real-life use (rather than trying to come up with the
      perfect version prior to going to market). It’s a tried and tested
      approach to innovation that is cheaper and easier than traditional
      methods.
    </p>
    <p>Want to create something brand new? We’re the crew for you.</p>
  </div>
  <h1 class="title service-tagline" class:is-mobile="{isMobile}">
    Redesign
    <br />
    <span class="cyan">how we do and experience.</span>
  </h1>
</div>

<svelte:window bind:innerWidth="{w}" />
