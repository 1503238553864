<style lang="scss">p:not(:last-child) {
  margin-bottom: 2rem;
}

strong {
  color: #141414;
}</style>

<p>
  We see R&D as core to evolution. That’s why we always adopt an holistic
  explorative approach to what we create - when one part of an ecosystem
  changes, every other part does as well. To be able to identify, monitor and
  adapt to changes - that’s the magic of conscious creation.
</p>

<p>
  When we create, we look at actual experiences of everyone (and everything!) in
  the ecosystem, and design for purposeful evolution. Our services include
  standalone workshops, 6-12 week innovation projects, program design and
  delivery, and digital product/service creation. We build for-purpose design
  and engineering teams to match the needs of each project.
</p>

<p>
  If you want to pivot, optimise your organisation, build digital tools or
  systems, or something more complex, we’re here to help. We love creating ways
  to lighten cognitive load, reduce time to complete tasks, reduce complexity or
  help explain, connect disparate groups and individuals, and make data-driven
  decisions effortless.
</p>

<p>
  <strong>What we do is simple:</strong>
  we help you frame the design challenge, explore the challenge holistically, create
  a set of design parameters, design it, develop it, test it, implement it, and observe/evolve
  over time. We work with your subject matter experts, combining our experience with
  their expertise to create purposeful and effective products and services.
</p>
