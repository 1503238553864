<script>
  let year = new Date().getFullYear();
</script>

<style lang="scss">p {
  font-size: 0.9rem;
}

a {
  color: #4a4a4a;
  padding: 0;
}
a:hover {
  color: #000;
}

.tiny {
  font-size: 0.6rem;
}</style>

<p class="tiny">
  &copy; {year} DNA PATHWAYS PTY LTD. All rights reserved.
</p>
<p class="tiny">
  <a href="/privacy-policy">Privacy Policy</a>
</p>
