<script>
  import Ideate from "./svg/ideate/Ideate.svg";
  import IdeateMobile from "./svg/ideate/IdeateMobile.svg";

  let w,
    isMobile = false;

  $: if (w <= 1024) {
    isMobile = true;
  } else {
    isMobile = false;
  }
</script>

<style lang="scss">.slide-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-flex-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 77%;
}

.mobile-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.section-title {
  color: #141414;
  font-size: 0.7rem;
}

.title {
  color: #141414;
}

h1.is-huge {
  max-width: 40rem;
  font-size: 5rem;
  letter-spacing: -0.2rem;
}

h1.is-huge-mobile {
  font-size: 3rem;
  letter-spacing: -0.1rem;
  text-align: center;
}

.svg-expand {
  min-height: 100%;
  min-width: 100%;
}

.slide-container {
  background-color: #00ff7d;
}

.ideate-label {
  position: absolute;
  padding: 0 12vw;
  color: #141414;
  width: 100%;
}

.ideate-label:first-child {
  top: -3vw;
}

.label-heading {
  font-weight: bold;
}

.svg-expand {
  position: relative;
  margin: 3rem 0;
  margin-bottom: 12vw;
}

.bottom-label {
  bottom: -1rem;
}

.bottom-label > * {
  text-align: right;
}</style>

<div class="slide-container">
  <div class="svg-flex-container" class:mobile-container="{isMobile}">
    {#if isMobile}
      <div class="section">
        <h6 class="title is-6 section-title">HOW WE WORK</h6>
      </div>
      <h1 class="title is-huge is-huge-mobile">Ideate</h1>
      <div class="svg-expand">
        <div class="ideate-label">
          <div class="label-heading">Core Insights</div>
          <p>Insights from discovery form the basis of ideation.</p>
        </div>
        <div class="ideate-label bottom-label">
          <div class="label-heading">Design Blueprint</div>
          <p>
            Take what we've learned and turn that into blueprints for products
            and services.
          </p>
        </div>
        {@html IdeateMobile}
      </div>
    {:else}
      {@html Ideate}
    {/if}
  </div>
</div>

<svelte:window bind:innerWidth="{w}" />
