<script>
  import Navbar from "../components/navbar/Navbar.svelte";
  import HeaderContainer from "../components/header/HeaderContainer.svelte";
  import WhyContainer from "../components/why/WhyContainer.svelte";
  import HowContainer from "../components/how/HowContainer.svelte";
  import WhatContainer from "../components/what/WhatContainer.svelte";
  import ServicesContainer from "../components/services/ServicesContainer.svelte";
  import GetStarted from "../components/getstarted/GetStarted.svelte";
  import Footer from "../components/footer/Footer.svelte";
  import Book from "../components/resources/Book.svelte";
  import Projects from "../components/projects/Projects.svelte";
</script>

<style lang="scss">.footer {
  padding-bottom: 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}</style>

<div>
  <Navbar />
</div>

<header>
  <HeaderContainer />
</header>

<main>
  <section id="why">
    <WhyContainer />
  </section>

  <section id="how">
    <HowContainer />
  </section>

  <section id="what">
    <WhatContainer />
  </section>

  <section id="services">
    <ServicesContainer />
  </section>

  <section id="projects">
    <Projects />
  </section>

  <section id="resources">
    <Book />
  </section>

  <section id="getStarted">
    <GetStarted />
  </section>
</main>

<footer id="footer" class="footer">
  <Footer />
</footer>

<!-- routify:options title="Design for life." -->
