<style lang="scss">.title {
  font-family: "BrownStd-Regular";
  color: #141414;
}

p {
  font-size: 0.9rem;
}</style>

<div class="title is-6">Locations</div>
<p>Australia</p>
<p>Georgia</p>
<p>Portugal</p>
